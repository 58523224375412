"use client";

import { createSlice } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { message } from 'antd';
import axios from 'axios';
import { axs } from '@axios/axsBaseUrl';

const { success, error: errorDialog } = Modal;

const initialState = {
  codigoFondo: null,
  numeroCuentaLengt: 0,
  codigoFormaPago: null,
  codigoLocalidad: null,
  codigoBanco: null,
  tipoCuenta: null,
  fondosDisponibles: [],
  cantidadRetirar: 0,
  penalidad: 0,
  calculosRetiro: {},
  formasPago: {},
  localidades: [],
  bancoPorFormaPago: {},
  tipoCuentaPorBanco: {},
  loading: false,
  error: false,
}

const slice = createSlice({
  name: 'retiroFCP',
  initialState,
  reducers: {
    resetearCalculoRetiro(state) {
      state.calculosRetiro = {};
    },
    getDetallesFCP(state, action) {
      state.codigoFondo = action.payload.codigoFondo
      state.fondosDisponibles = action.payload.data;
    },
    getCalculoRetiro(state, action) {
      return {
        ...state,
        cantidadRetirar: action.payload.montoRetiro,
        penalidad: action.payload.sinPenalidad,
        calculosRetiro: action.payload.data,
      }
    },
    setFormasPago(state, action) {
      state.formasPago = action.payload;
    },
    setLocalidades(state, action) {
      state.localidades = action.payload;
    },
    setBancoPorFormaPago(state, action) {
      state.codigoFormaPago = action.payload.codigoFormaPago;
      state.bancoPorFormaPago = action.payload.data;
    },
    setTipoCuentaPorBanco(state, action) {
      state.tipoCuentaPorBanco = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
    setNumeroCuentaLength(state, action) {
      // console.log(action.payload);
      state.numeroCuentaLengt = action.payload;
    },
    resetConfirmarRetiro(state) {
      state.localidades = [];
      state.formasPago = [];
      state.tipoCuentaPorBanco = [];
      state.tipoCuenta = null;
      state.bancoPorFormaPago = [];
      state.numeroCuentaLengt = 0;
    }
  }
});

export const consultarFondosRetiros = (cedula, codigoFondo) => async (dispatch) => {
  const { getDetallesFCP } = slice.actions;
  try {
    const url = `/transactional/consultaFondoRetiros/${cedula}/${codigoFondo}`;
    const { data } = await axs.get(url, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    dispatch(getDetallesFCP({ data, codigoFondo }));
  } catch (error) {
    console.log(error);
  }
}

export const consultarSaldoRetiro = (datos) => async (dispatch) => {
  const { getCalculoRetiro } = slice.actions;
  const { montoRetiro, sinPenalidad } = datos;

  try {
    const url = `/transactional/consultarSaldoRetiro`;
    const { data } = await axs.post(url, datos, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    // console.log({data});

    dispatch(getCalculoRetiro({ data, montoRetiro, sinPenalidad }));
  } catch (error) {
    console.log(error);
    const title = 'Error!'
    let content = 'Error al consultar el saldo del retiro';
    if (axios.isAxiosError(error)) {
      content = error.response.data.message;
    }
    message.error({
      title,
      content,
    })
  }
}

export const consultarFormasPago = (codigoFondo) => async (dispatch) => {
  const url = `/transactional/formaPagoFondo/${codigoFondo}`;
  try {
    const { data } = await axs.get(url, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    dispatch(slice.actions.setFormasPago(data));
  } catch (error) {
    console.log(error);
  }
}

export const consultarLocalidades = () => async (dispatch) => {
  const url = `/transactional/localidad`;
  try {
    const { data } = await axs.get(url, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    dispatch(slice.actions.setLocalidades(data));
  } catch (error) {
    console.log(error);
  }
}

export const consultarbancoPorFormaPago = ({ codigoFondo, codigoFormaPago }) => async (dispatch) => {
  const url = `/transactional/bancoPorFormaDePago/${codigoFondo}/${codigoFormaPago}`;
  try {
    const { data } = await axs.get(url, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    dispatch(slice.actions.setBancoPorFormaPago({ data, codigoFormaPago }));
  } catch (error) {
    console.log(error);
  }
}

export const consultarTipoCuentaPorBanco = ({ codigoFondo, codigoFormaPago, codigoBanco }) => async (dispatch) => {
  try {
    const url = `/transactional/tipoCuentaPorBanco/${codigoFondo}/${codigoFormaPago}/${codigoBanco}`;
    // console.log(url);
    const { data } = await axs.get(url, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    // console.log(data);
    dispatch(slice.actions.setTipoCuentaPorBanco(data));
  } catch (error) {
    console.log(error);
  }
}

export const startRetiro = (values, callback) => async (dispatch, getState) => {
  const { retiroFCP, login } = getState();
  const { resetearCalculoRetiro } = slice.actions;

  const detalleRetiro = retiroFCP.calculosRetiro.detalleRetiro.map(detalle => ({
    folio: detalle.folio,
    fechaDeposito: detalle.fechaDeposito.split(' ')[0],
    montoRetiro: detalle.montoRetiro,
    porcentajeRetiro: detalle.porcentajeRetiro,
    montoComision: detalle.montoComisionRT,
    porcentajeComision: detalle.porcentajeComisionRT
  }));

  const identificacionCliente = login.user.numero_identificacion || login.user.ruc;

  const retiroObj = {
    identificacionCliente,
    codigoFondo: retiroFCP.codigoFondo,
    montoTotalRetiro: retiroFCP.cantidadRetirar,
    sinPenalidad: retiroFCP.penalidad,
    motivoRetiro: '',
    codigoLocalidad: values.codigoLocalidad,
    codigoFormaPago: values.codigoFormaPago,
    codigoBanco: values.codigoBanco,
    tipoCuenta: values.tipoCuenta,
    numeroCuenta: `${values.numeroCuenta}`,
    detalleRetiro
  }

  //console.log(retiroObj);

  try {
    const { data } = await axs.post(`/transactional/realizarRetiro`, retiroObj, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    dispatch(slice.actions.setIsLoading(false));
    success({
      title: 'Retiro realizado con éxito',
      content: 'Su retiro se encuentra en proceso de aprobación, este proceso puede demorar hasta 48 horas.',
      okButtonProps: {
        style: {
          marginLeft: 'auto'
        }
      },
      okText: 'Aceptar',
      onOk: () => {
        dispatch(resetearCalculoRetiro());
        callback(`/transacciones/${retiroFCP.codigoFondo}`)
      }
    });

  } catch (error) {
    dispatch(slice.actions.setIsLoading(false));
    const title = 'Error al realizar el retiro';
    let content = 'Por favor intente nuevamente';
    if (axios.isAxiosError(error)) {
      content = error.response.data.message;
    }
    errorDialog({
      title,
      content,
      okButtonProps: {
        style: {
          marginLeft: 'auto'
        }
      },
      okText: 'Aceptar',
    });
  }
}

export const { resetearCalculoRetiro, setIsLoading, setNumeroCuentaLength, resetConfirmarRetiro } = slice.actions

export default slice.reducer;
