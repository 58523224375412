"use client";

import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';


const initialState = {
    caracteristicas: [],
    caracteristica: {
        id: null,
        titulo: null,
        descripcion: null,
        url: null,
        orden: null,
    },
    loading: false,
    error: false,
    loaded: false
};

// find, findOne, create, update, remove

const slice = createSlice({
    name: 'caracteristicas',
    initialState,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSuccess(state, action) {
            state.loading = false;
            state.caracteristicas = [...state.caracteristicas, action.payload];
        },
        createError(state, action) {
            state.loading = false;
            state.error = true;
        },

        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateSuccess(state, action) {
            //busco el inidice
            const index = state.caracteristicas.findIndex(item => item.id == action.payload.id);
            // reemplazo en un auxiiliar
            const aux = [...initialState.caracteristicas];

            aux[index] = action.payload;
            //modifico store
            state.loading = false;
            state.caracteristicas = aux;
        },
        updateError(state, action) {

            state.error = action.payload;
            state.loading = false;
        },




        fillForm(state, action) {
            state.caracteristica = action.payload;
        },
        cleanForm(state, action) {
            state.caracteristica = initialState.caracteristica;
        },

    }
});


export const createCaracteristica = (payload) => async (dispatch, callback) => {
    const { createSuccess, createError, createFetch } = slice.actions;


    try {
        dispatch(createFetch());
        const { data } = await cs_axs.post(`/cms/caracteristicas`, payload);
        callback();
        dispatch(createSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(createError(e));
    }
}


export const updateCaracteristica = (payload) => async (dispatch, callback) => {

    const { updateFetch, updateSuccess, updateError } = slice.actions;
    try {
        dispatch(updateFetch());
        const { data } = await cs_axs.put(`/cms/caracteristicas/${payload.id}`, payload);
        callback();
        dispatch(updateSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(updateError(e));
    }
}


export const fillFormCaracteristica = (payload) => async (dispatch, cb) => {

    payload.id ? dispatch(slice.actions.fillForm(payload))
        : dispatch(slice.actions.cleanForm());

}




export default slice.reducer;




