"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { setSession } from '@axios/utils';
import { axs } from '@axios/axsBaseUrl';
import { findResumen } from './resumenPersona';
import { paises } from '@utils/catalogos_negocio';



const initialState = {
    user: null,
    ldap: null,
    token: null,
    loading: false,
    loadingLdap: false,
    loadingSession: true,
    redirectToNewForm: false,
    redirectToPassword: false,
    redirectToIpUpdate: false,
    residenciaFiscal: null,
    preguntasSeguridad: [],
    paises: [],
    datosClienteWeb: null,
    ipComputer: null,
    error: false,
    errorLdap: false,
    routes: [],
    validationQuestions: null,
    loadingRSF: false,
};

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {

        resetStateLogin: (state) => {
            state.user = null,
                state.ldap = null,
                state.token = null,
                state.loading = false,
                state.loadingLdap = false,
                state.loadingSession = true,
                state.redirectToNewForm = false,
                state.redirectToPassword = false,
                state.redirectToIpUpdate = false,
                state.residenciaFiscal = null,
                state.preguntasSeguridad = [],
                state.datosClienteWeb = null,
                state.ipComputer = null,
                state.error = false,
                state.errorLdap = false,
                state.validationQuestions = null,
                state.loadingRSF = false
        },

        // LOGIN
        loginFetch(state) {
            state.loading = true;
            state.loadingSession = true;
            state.error = false;
        },
        loginSucess(state, action) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.loading = false;
            state.loadingLdap = false;
            state.loadingSession = false;
            state.error = false;
            state.errorLdap = false;
        },
        updateUser(state, action) {
            state.user = { ...state.user, ...action.payload }
        },
        loginError(state, action) {
            state.error = action.payload;
            state.loading = false;
            state.loadingLdap = false;
            state.loadingSession = false;
        },

        // LOGIN LDAP
        loginLdapFetch(state) {
            state.loadingLdap = true;
            state.ldap = null;
            state.errorLdap = false;
            state.redirectToPassword = false;
        },
        loginLdapSucess(state, action) {
            state.ldap = action.payload;
            state.redirectToPassword = true;
            state.loadingLdap = false;
            state.errorLdap = false;
        },
        loginLdapError(state, action) {
            state.redirectToPassword = false;
            state.errorLdap = action.payload;
            state.loadingLdap = false;
        },
        updateLdap(state, action) {
            state.ldap = action.payload;
        },
        updateLdapRedirect(state, action) {
            state.redirectToPassword = action.payload;
            if (action.payload === false) state.ldap = null;
        },
        updateRedirectNewForm(state, action) {
            state.redirectToNewForm = action.payload;
        },

        updateRedisidenciaFiscal(state, action) {
            state.residenciaFiscal = action.payload;
        },

        changeLoadingSession(state, action) {
            state.loadingSession = action.payload;
        },

        changeLoadingLdap(state, action) {
            state.loadingLdap = action.payload;
        },

        changeRedirectToIpUpdate(state, action) {
            state.redirectToIpUpdate = action.payload;
        },

        changeIpComputer(state, action) {
            state.ipComputer = action.payload;
        },

        changeValidationQuestions(state, action) {
            state.validationQuestions = action.payload;
        },

        //Logout
        logOutFetch(state) {
            state.user = null;
            state.ldap = null;
            state.token = null;
            state.loading = false;
            state.redirectToPassword = false;
            state.loadingLdap = false;
            state.loadingSession = false;
            state.error = false;
        },
        setPreguntasSeguridad(state, action) {
            state.preguntasSeguridad = action.payload;
        },

        //Datos Cliente Web
        setDatosClienteWeb(state, action) {
            state.datosClienteWeb = action.payload;
        },

        setPaises(state, action) {
            state.paises = action.payload;
        },

        //MENU
        setMenu(state, action) {
            const auxRoutes = [...action.payload.routes];
            //CARGA NOTIFICACIONES EN MENU // CUENTA LEIDAS
            // const auxNotiIndex = auxRoutes.findIndex(item => item.key === 'notificaciones');
            // const countLeidas = action.payload.notificaciones.find(item => item.leida === false) ? false : true;
            // auxRoutes[auxNotiIndex].leida = countLeidas,
            // auxRoutes[auxNotiIndex].routes = action.payload.notificaciones.map(({ titulo, descripcion, id, imagen, leida }) => ({
            //     id,
            //     titulo,
            //     slug: `notificaciones/${id}`,
            //     descripcion,
            //     leida,
            //     imagen
            // }))
            //SET STATE;
            state.routes = auxRoutes;
        },

        updateDatosUser(state, action) {
            state.user = action.payload;
        },

        updateLoadingRSF(state, action) {
            state.loadingRSF = action.payload;
        }



    }
});


export const {
    resetStateLogin,
    loginFetch,
    loginSucess,
    loginError,
    loginLdapFetch,
    loginLdapSucess,
    loginLdapError,
    updateLdap,
    updateLdapRedirect,
    updateRedisidenciaFiscal,
    setPreguntasSeguridad,
    setDatosClienteWeb,
    setPaises,
    updateRedirectNewForm,
    changeLoadingSession,
    changeRedirectToIpUpdate,
    changeIpComputer,
    logOutFetch,
    updateUser,
    changeValidationQuestions,
    changeLoadingLdap,
    updateDatosUser,
    updateLoadingRSF
} = slice.actions;

export const LogOutApp = (router) => async (dispatch) => {
    setSession(); // vuela axios vuela localStorage;
    dispatch(resetStateLogin());
    router.push('/auth/login');
    //callback();

    // callback('/auth/login'); // limpia el store
}


export const loginUsersMe = (callback = false) => async (dispatch) => {
    const { loginFetch, loginSucess, loginError } = slice.actions;
    dispatch(loginFetch());
    const token = localStorage.getItem('token') || null;
    // debugger;
    if (!token) {
        dispatch(loginError("NO HAY TOKEN EN EL STORAGE"));
        //setSession(null); // ESTO NO ES REDUX
        return;
    }

    try {
        const { data } = await axs.get(`/session/me`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        //console.log(data);
        // debugger;

        //debugger
        // console.log('ide, ruc' +  data.user.numero_identificacion || data.user.ruc)

        if (data.ok && data.user) {
            dispatch(loginSucess({ user: data.user, token }));
            dispatch(findResumen(data.user.numero_identificacion || data.user.ruc, token));
            dispatch(consultaLdapUserLoginMe({ usuario: data.user.numero_identificacion || data.user.ruc }, callback));
        }
        if (data.ok == false) {
            dispatch(loginError(data.message));
            message.error(data.message);
            //setSession(null);
        }
    }
    catch (e) {
        //message.error('Error interno del servidor!');
        dispatch(loginError(e));
        callback && callback(false)
        //setSession(null);
    }
}


export const updateUserLogged = (consulta) => async (dispatch) => {
    const { loginFetch, loginSucess, loginError } = slice.actions;
    try {
        dispatch(loginFetch());
        const token = localStorage.getItem('token') || '';
        const { data } = await axs.get(`/user/login`, consulta, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
        if (data.ok == false) {
            throw new Error(data.message)
        }

        setSession(data.token);
        dispatch(loginSucess({ user: data.user, token: data.token }));

    }
    catch (e) {
        console.log(e)
        setSession(null)
        dispatch(loginError(e))
    }
}


/** INICIO SESION NUEVO */
export const consultaLdap = (consulta) => async (dispatch) => {
    try {
        dispatch(changeLoadingLdap(true));
        dispatch(updateLdapRedirect(false));
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/ldap/${consulta.usuario}`,
            {
                headers: {
                    'guard': 'BEFORETRANSAC',
                    Authorization: `Bearer ${token}`, 'x-access-token': token
                }
            });

        //console.log(data);

        //no existe
        if (data.cod_error == '99') {
            dispatch(changeLoadingLdap(false));
            return message.error('Atención, usuario no registrado por favor, comunicarse con Servicios al Cliente.');

        }


        if (!data.ok) {

            if (!data.ok && data.openNewForm) {
                dispatch(updateRedirectNewForm(true));
                dispatch(changeLoadingLdap(false));
                return;
            }

            dispatch(loginLdapError(data.message));
            dispatch(changeLoadingLdap(false));
            message.error(data.message);
            setSession(null);
        }


        if (data.ok && data.ldap) {

            dispatch(updateLdap(data.ldap));

            if (data.ldap.clientstatus == 'blocked') {
                message.error(`El usuario se encuentra bloqueado`);
                dispatch(changeLoadingLdap(false));
                return;
            };


            // //VALIDACION DE IP
            dispatch(changeIpComputer(data.ipClient));

            // console.log(data.ipClient);
            // console.log(data.ldap.clientidcomputer);
            // console.log('is equal: ', data.ipClient == data.ldap.clientidcomputer ? true : false);

            if (data.ipClient !== data.ldap.clientidcomputer && data.ldap.isnewclient == 'N') {
                dispatch(changeRedirectToIpUpdate(true));
                dispatch(changeLoadingLdap(false));
                return;
            }

            dispatch(updateLdapRedirect(true));
            dispatch(changeLoadingLdap(false));

        }

    }
    catch (e) {
        //message.error('Error interno del servidor!');
        dispatch(loginLdapError(e));
        setSession(null);
    }


}

export const loginLdapApp = (consulta) => async (dispatch) => {
    try {
        dispatch(changeLoadingLdap(true));

        if (consulta.confirmIpUpdate && consulta.ip) {
            const token = localStorage.getItem('tokenBefore') || '';
            const result = await axs.post(`/user/updateIpComputer`, consulta,
                {
                    headers: {
                        'guard': 'BEFORETRANSAC',
                        Authorization: `Bearer ${token}`, 'x-access-token': token
                    }
                })

            if (!result.data.ok) {
                message.warning('Ip no ha sido actualizada');
                return;
            }
        }


        // console.log({consulta});
        const { data } = await axs.post(`/session/login`, consulta,
            { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'TRANSAC' } });

        //console.log({ data });

        //data.user.fecha_nacimiento = "1982/04/05 00:00:00";


        if (data.ok && data.user && data.token) {
            localStorage.setItem('token', data.token);
            dispatch(loginSucess({ user: data.user, ldap: data.ldap, token: data.token }));
            dispatch(findResumen(data.user.numero_identificacion || data.user.ruc, data.token));
            setSession(data.token);
            //message.success('Inicio de Sesión Exitoso');
            notification.success({
                message: 'Bienvenido!',
                description: `Inicio de Sesión Exitoso.`,
                duration: 5,
            });
            return;
        }

        if (!data.ok || !data.token) {
            dispatch(loginError(data.message));
            notification.error({
                message: 'Error',
                description: data.message,
                duration: 5,
            });
            setSession(null); // ESTO NO ES REDUX
            return;
        }

    }
    catch (e) {
        console.log(e);
        //message.error('Error interno del servidor!');
        dispatch(loginError(e));
        setSession(null); // ESTO NO ES REDUX
    }
}

export const setMenu = ({ notificaciones }) => async (dispatch) => {
    const routes = [
        {
            key: "inicio",
            titulo: "Inicio",
            slug: "transacciones",
            icon: "inicio",
        },
        // {
        //     key: "notificaciones",
        //     titulo: "Notificaciones",
        //     icon: "notificaciones",
        // },
        // {
        //     key: "incremento",
        //     titulo: "Incremento",
        //     slug: "transacciones/incremento",
        //     icon: "incremento"

        // },
        {
            titulo: "Beneficios",
            icon: "serviciosLinea",
            routes: [
                {
                    key: "GlobalSalud",
                    titulo: "Global Salud",
                    slug: "transacciones/beneficios/globalSalud",
                    icon: 'globalSalud'
                }
            ]
        },
        {
            key: 'serviciosLinea',
            titulo: 'Servicios en línea',
            icon: 'serviciosLinea',
            routes: [
                {
                    key: "actulizarDatos",
                    titulo: "Actualizar datos",
                    slug: "transacciones/perfil/update",
                    icon: "update",
                },
                {
                    key: "siniestros",
                    titulo: "Reportar un siniestro",
                    slug: "transacciones/siniestros",
                    icon: "siniestros",
                },
                // {
                //     key: "asistencia",
                //     titulo: "Centro de soluciones",
                //     icon: "asistencia",
                //     slug: 'solicitudes'
                // },
                // {
                //     key: "panelNotificaciones",
                //     titulo: "Panel Notificaciones",
                //     icon: "notificaciones",
                //     slug: 'notificaciones/panel'
                // }

            ]
        },
        // {
        //     key: "usuario",
        //     titulo: "Usuario",
        //     icon: "usuario",
        //     slug: "perfil/update",
        //     routes: [
        //         {
        //             key: "perfil",
        //             titulo: "Perfil",
        //             slug: "perfil/update",
        //             icon: "perfil",
        //         },
        //         {
        //             key: "logout",
        //             titulo: "Cerrar Sesión",
        //             slug: "transacciones/logout",
        //             icon: "logout",
        //         }
        //     ]
        // },
        // {
        //     key: "logout",
        //     titulo: "Cerrar Sesión",
        //     slug: "transacciones/logout",
        //     icon: "logout",
        // }
    ];


    dispatch(slice.actions.setMenu({ notificaciones, routes }))

}

export const consultaLdapUserLoginMe = (consulta, callback) => async (dispatch) => {
    try {
        const token = localStorage.getItem('token') || '';
        const { data } = await axs.get(`/user/ldap/${consulta.usuario}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        if (!data.ok) {
            dispatch(loginLdapError(data.message));
            message.error(data.message);
            setSession(null);
        }

        if (data.ok && data.ldap) {
            dispatch(updateLdap(data.ldap));
        }
        // callback && callback(false);
    }
    catch (e) {
        //message.error('Error interno del servidor!');
        dispatch(loginLdapError(e));
        setSession(null);
        callback && callback(false);
    }


}


//validacion pregunta secreta
export const validarPreguntas = (consulta) => async (dispatch) => {
    const { id, state } = consulta;
    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/validarPreguntas/${id}`,
            { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            dispatch(setPreguntasSeguridad(data.preguntas));
            localStorage.setItem('sqt', JSON.stringify(data.crypt));

            if (state) {
                state(true);
            }
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        console.log(e);
    }
}


export const validarRespuestas = (consulta) => async (dispatch) => {

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/validarRespuestas`, consulta,
            {
                headers: {
                    'guard': 'BEFORETRANSAC',
                    Authorization: `Bearer ${token}`,
                    'x-access-token': token
                }
            });

        dispatch(changeValidationQuestions(null));
        dispatch(changeValidationQuestions(data.ok));
        data.ok && message.success(data.message);

    } catch (error) {
        console.log(error);
    }
}


export const datosClienteW = (consulta) => async (dispatch) => {
    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/datosClienteWeb/${consulta}`, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            dispatch(setDatosClienteWeb(data.data));
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        console.log(e);
    }
}


export const solicitarClaveSecreta = (data, onFinish, callback, formResetPassword) => async (dispatch) => {

    const { identificacion, canal_envio } = data;

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/recibirCodigoWeb/${identificacion}/${canal_envio}`, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        if (!data.ok) {
            notification.error({
                message: '¡Error!',
                description: data.message
            });
        }

        if (data.ok) {
            notification.success({
                message: '¡Éxito!',
                description: data.message
            });
            onFinish();
            callback && callback(false);
            formResetPassword && formResetPassword.resetFields();
        }

    }
    catch (e) {
        console.log(e);
        notification.error({
            message: '¡Error!',
            description: data.message || 'Error interno del servidor!'
        });
    }
}


export const updateLdapUser = (datos, onFinish) => async (dispatch) => {
    //console.log(datos);

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/updateLdap`, datos, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        //  console.log(data);
        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            message.success(data.message);
            onFinish();
        }

    } catch (error) {

    }
}


export const sendMessageOtroCanal = (datos, onFinish, setLoading) => async (dispatch) => {
    // console.log(datos);

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/sendMessageOtroCanal`, datos, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        // console.log(data);
        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            message.success(data.message);
            onFinish();
        }

        setLoading(false);

    } catch (error) {
        setLoading(false);
        console.log(error);
    }
}


export const updateIpComputer = (datos, onFinish) => async (dispatch) => {
    // console.log(datos);

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/updateIpComputer`, datos, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        // console.log(data);
        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            message.success(data.message);
            onFinish();
        }

    } catch (error) {

    }
}




export const getListCountries = () => async (dispatch) => {
    dispatch(setPaises(paises));
}


// NUEVA REFACTORIZACION

export const validarPreguntasSecretasLdap = ({ usuario, pregunta, respuesta }) => async (dispatch) => {

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/validarPreguntasLdap/${usuario}`, { pregunta, respuesta },
            { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });


        if (!data.ok) {
            message.error(data.message);
        }

        if (data.ok) {
            dispatch(updateLdapRedirect(true));
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        console.log(e);
    }
}


export const validarResidenciaFiscal = (usuario, modal, modalRSFForm, showDatosUpdate, preguntasResidencia) => async (dispatch) => {

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/validarResidenciaFiscal/${usuario}`,
            { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });


        if (data.ok) {
            dispatch(updateRedisidenciaFiscal(data.actualizaDatos));

            //valida si se muestra formulario residencia fiscal
            if (data.ok && data.actualizaResidencia?.verifica == 1 && data.preguntasResidencia && data.preguntasResidencia?.length > 0) {
                modal(true);
                modalRSFForm(true);
                preguntasResidencia(data.preguntasResidencia);
            }

            //valida si se muestra formulario datos personales
            if (data.ok && data.actualizaDatos.actualiza_datos == 1) {
                modal(true);
                showDatosUpdate(true);
            }

        }
    }
    catch (e) {
        message.error('Error interno del servidor!');
        console.log(e);
    }
}


export const updateResidenciaFiscal = (datos, modalRSF) => async (dispatch) => {
    dispatch(updateLoadingRSF(true));
    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/updateResidenciaFiscal`, datos,
            { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        console.log(data);

        setTimeout(() => {
            dispatch(updateLoadingRSF(false));

            if (data.ok) {
                data.datosUpdated && dispatch(updateDatosUser(data.datosUpdated.personaNatural ? data.datosUpdated.personaNatural : data.datosUpdated.personaJuridica));
                notification.success({
                    message: '¡Éxito!',
                    description: 'Datos actualizados correctamente'
                });
                modalRSF(false);
            }

            if (!data.ok) {
                //message.error(data.message);
                notification.error({
                    message: '¡Error!',
                    description: data.message
                });
            }
        }, 3000);

    }
    catch (e) {
        //message.error('Error interno del servidor!');
        dispatch(updateLoadingRSF(false));
        notification.error({
            message: '¡Error!',
            description: `${e.response.data.message || 'Error interno del servidor!'}`
        });
        console.log(e.response);
    }
}

export default slice.reducer;