"use client";

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { message } from 'antd';
import { updateUser } from './login';
import { axs } from '@axios/axsBaseUrl';

const updateSuccessMsg = (msg) => {
    message.success(msg);
};


const errorMsg = (msg) => {
    message.error(msg);
};




const initialState = {
    personaJuridica: {},
    personaNatural: {},
    fechaPrimerAporte: '',
    fechaInicioCiclo: '',
    fechaFinCiclo: '',
    rendimiento: null,
    permanencia: {},
    saldos: [],
    novedad: false,
    error: false,

    loadingUpdate: false,
    loading: false,
    intentos: 0
};

const slice = createSlice({
    name: 'resumenPersona',
    initialState,
    reducers: {
        resetState(state) {
            state.personaJuridica = {},
                state.personaNatural = {},
                state.saldos = [],
                state.novedad = false,
                state.error = false,

                state.loadingUpdate = false,
                state.loading = false,
                state.intentos = 0
        },
        findResumenFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findResumenSuccess(state, action) {
            const { payload } = action;
            state.personaJuridica = payload.personaJuridica;
            state.personaNatural = payload.personaNatural;
            state.saldos = payload.saldos;
            state.novedad = payload.novedad;
            state.error = payload.error;
            state.loading = false;
        },
        findResumenError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        findResumenIntentos(state, action) {
            state.intentos = state.intentos += 1
        },

        //UPDATE
        updateResumenFetch(state) {
            state.loadingUpdate = true;
            state.error = false;
        },
        updateResumenSucess(state, action) {
            state.loadingUpdate = false;
            state.error = false;
        },
        updateResumenError(state, action) {
            state.error = action.payload;
            state.loadingUpdate = false;
        },
        getCicloAporte(state, action) {
            state.fechaPrimerAporte = action.payload.fechaPrimerAporte;
            state.fechaInicioCiclo = action.payload.fechaInicioCiclo;
            state.fechaFinCiclo = action.payload.fechaFinCiclo;
        },
        startLoading(state) {
            state.loading = true;
        },
        getPermanencia(state, action) {
            state.permanencia = action.payload;
        },
        getRendimiento(state, action) {
            state.rendimiento = action.payload;
        }
    }
});

export const { resetState } = slice.actions;

export const findResumen = (di, token) => async (dispatch) => {
    const { findResumenFetch, findResumenSuccess, findResumenError } = slice.actions;
    if (token == null) {
        dispatch(findResumenError("NO HAY TOKEN EN EL STORAGE"));
        return;
    }

    try {

        dispatch(findResumenFetch());
        const { data } = await axs(`/transactional/resumen_cliente/${di}`, { headers: { 'guard': 'TRANSAC', 'x-access-token': token } });
        // const data = await response.json();

        if (data.ok && data.client) {
            dispatch(findResumenSuccess(data.client));
        }

        if (!data.ok && data.message.novedad == null) {
            dispatch(findResumenError(data.message.error));
            message.error('Error al obtener datos del usuario, intente mas tarde.');
        }


        if (!data.ok && data.message.novedad != null) {
            dispatch(findResumenError(data.message.novedad));
            message.error(data.message.novedad);
        }

    } catch (e) {
        //message.error('Error interno del servidor!');
        dispatch(findResumenError(e));
        console.error(e)
    }
};

export const updateResumenPersona = (person) => async (dispatch) => {
    const { updateResumenError, updateResumenSucess, updateResumenFetch } = slice.actions;
    const token = localStorage.getItem('token');

    try {
        dispatch(updateResumenFetch());
        const { data } = await axs.post(`/transactional/update_cliente`, person, { headers: { Authorization: `Bearer ${token}`, 'x-access-token': token, 'guard': 'TRANSAC' } });

        //console.log({ data });
        if (data.error) {
            updateResumenError(data.message);
            return;
        }

        // console.log({person});
        dispatch(updateUser(person));
        dispatch(updateResumenSucess());
        updateSuccessMsg(data.message);
    } catch (e) {
        console.error(e)
        dispatch(updateResumenError(e));
        if (axios.isAxiosError(e)) {
            errorMsg(e.response.data.message);
            return;
        }
        errorMsg('Error al actualizar los datos del usuario, intente mas tarde.');
    }
}

export const startGetCicloAportePersona = (uid, fondo = '000001', callback, router) => async (dispatch) => {
    const { getCicloAporte } = slice.actions;
    try {
        const token = localStorage.getItem('token');
        const { data } = await axs.get(`/transactional/CicloAport/${uid}/${fondo}`, { headers: { Authorization: `Bearer ${token}`, 'x-access-token': token, 'guard': 'TRANSAC' } });

        if (data.ok) {
            callback(false);
            dispatch(getCicloAporte({ ...data }));
            return;
        }

        message.error(data.message);
    } catch (error) {
        console.log(error);
        message.error('Error al obtener el ciclo de aporte');
    }
    router('/transacciones');
}

export const startGetRendimiento = (uid, fondo = '000001', callback, router) => async (dispatch) => {
    const { getRendimiento } = slice.actions;
    try {
        const token = localStorage.getItem('token');
        const { data } = await axs.get(`/transactional/rendimiento/${uid}/${fondo}`, { headers: { Authorization: `Bearer ${token}`, 'x-access-token': token, 'guard': 'TRANSAC' } });
        if (data.ok) {
            dispatch(getRendimiento(data.rendimiento));
            callback(false);
            // dispatch(startGetPermanencia(uid, fondo, callback, router));
            return;
        }

        message.error(data.message);
    } catch (error) {
        console.log(error);
        message.error('Error al obtener el rendimiento');
    }
    router('/transacciones')
}

export const startGetPermanencia = (uid, fondo, callback) => async (dispatch) => {
    const { getPermanencia } = slice.actions;
    try {
        const token = localStorage.getItem('token');
        // console.log(`/transactional/permanencia/${uid}/${fondo}`);
        const { data } = await axs.get(`/transactional/permanencia/${uid}/${fondo}`, { headers: { Authorization: `Bearer ${token}`, 'x-access-token': token, 'guard': 'TRANSAC' } });

        if (data.ok) {
            dispatch(getPermanencia(data));
            callback(false);
            return;
        }

        message.error(data.message);
        callback(false);
    } catch (error) {
        console.log(error);
        message.error('Error al obtener la permanencia');
        callback(false);
    }
    // router('/transacciones');
}

export default slice.reducer;