"use client";

import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';
import { jsFileToBlob } from '@utils/fileUtils';

const init = {
    figuras: [],
    figura: {},
    loading: false,
    error: false,
    progress: 0,

};


const slice = createSlice({
    name: "figura",
    initialState: init,
    reducers: {
        createFiguraFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        createFiguraSuccess(state, action) {
            state.loading = false;
            state.figuras.push(action.payload)
            state.figura = action.payload
        },
        createFiguraProgress(state, action) {
            state.progress = action.payload;
        },
        createFiguraErr(state, action) {
            state.loading = false;
            state.error = action.payload
        },


        readFigurasFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        readFigurasSuccess(state, action) {
            state.loading = false;
            state.figuras = action.payload
        },
        readFigurasErr(state, action) {
            state.loading = false;
            state.error = action.payload
        },



        setCurrent(state, action) {
            state.figura = action.payload;
        }
    }
});

export const createFigura = (file) => async (dispatch) => {


    const { createFiguraFetch, createFiguraProgress, createFiguraSuccess, createFiguraErr } = slice.actions;
    dispatch(createFiguraFetch());
    try {
        const blobFile = await jsFileToBlob(file);
        const formData = new FormData()


        formData.append("File", blobFile);
        formData.append("name", file.uid)
        formData.append("ext", file.type.split("/")[1])


        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    dispatch(createFiguraProgress(percent));
                }
            }
        };
        cs_axs.post(`/cms/figuras`, formData, options).then(res => {

            if (res.data && res.data.id) {


                const figura = {
                    ...res.data,
                    figuraUrl: `${process.env.API_URL}/figuras/${res.data.id}`
                }

                dispatch(createFiguraSuccess(figura))
            }
        });
    } catch (error) {
        dispatch(createFiguraErr(error));

    }
};



export const readFiguras = () => async (dispatch) => {

    const { readFigurasFetch, readFigurasSuccess, readFigurasErr } = slice.actions;
    dispatch(readFigurasFetch());
    try {
        const { data } = await cs_axs.get(`/cms/figuras/0/100`);
        const payload = data.map(item => (
            {
                ...item,
                figuraUrl: `${process.env.API_URL}/figuras/${item.id}.${item.ext}`
            }
        ));

        dispatch(readFigurasSuccess(payload));
    } catch (error) {
        dispatch(readFigurasErr(error));

    }
}

export const setCurrentFigura = (figura) => async (dispatch) => {

    dispatch(slice.actions.setCurrent(figura));

}


export default slice.reducer;
