"use client";

import { createSlice } from "@reduxjs/toolkit";
import { message, notification } from "antd";
import { axs } from "@axios/axsBaseUrl";

const initialState = {
  listadoBeneficiarios: [],
  novedad: "",
  error: false,
  isLoading: true,
  estadoGlobalSalud: null,
  errorGlobalSalud: false,
  novedadGlobalSalud: "",
  beneficios: [],
  currentBeneficio: {},
  parentescos: [],
  newBeneficiarios: [],
}

const slice = createSlice({
  name: "beneficiarios",
  initialState,
  reducers: {
    getBeneficiarios(state, action) {
      state.listadoBeneficiarios = action.payload;
      state.isLoading = false;
    },
    deleteBeneficiario(state, action) {
      state.listadoBeneficiarios = state.listadoBeneficiarios.filter(({ cedulaBeneficiario }) => cedulaBeneficiario !== action.payload);
      state.isLoading = false;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    getEstadoGlobalSalud(state, action) {
      state.estadoGlobalSalud = action.payload;
      state.isLoading = false;
    },
    setErrorGlobalSalud(state, action) {
      state.errorGlobalSalud = true;
      state.novedadGlobalSalud = action.payload;
      state.isLoading = false;
    },
    getBeneficios(state, action) {
      state.beneficios = action.payload;
      state.isLoading = false;
    },
    setCurrentBeneficio(state, action) {
      state.currentBeneficio = action.payload;
      state.isLoading = false;
    },
    setParentescos(state, action) {
      state.parentescos = action.payload;
      state.isLoading = false;
    },
    setNewBenficiarios(state, action) {
      state.newBeneficiarios = action.payload;
      state.isLoading = false;
    },
  },
})

export const startGetBeneficiarios = (userId, codigoFondo) => async (dispatch) => {
  const { getBeneficiarios } = slice.actions;
  try {
    // const { data } = await axios.get(`${process.env.API_URL}/transactional/beneficiarios/${userId}/${codigoFondo}`);
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/beneficiarios/${userId}/${codigoFondo}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    if (!!data.novedad.length) return dispatch(getBeneficiarios([]));

    dispatch(getBeneficiarios(data.listado));
  } catch (error) {
    console.log(error);
    dispatch(getBeneficiarios([]));
  }
}

export const startDeleteBeneficiario = (dataToDelete) => async (dispatch) => {
  const { deleteBeneficiario, startLoading, stopLoading } = slice.actions;
  dispatch(startLoading());
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.post(`/transactional/eliminarBeneficiario`, dataToDelete, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
    // console.log(data);
    // dispatch(deleteBeneficiario(dataToDelete.cedulaBeneficiario));
    if (!data.error) return dispatch(deleteBeneficiario(dataToDelete.cedulaBeneficiario));
  } catch (error) {
    console.log(error);
    dispatch(stopLoading());
  }
}

export const getEstadoGlobalSalud = (userId) => async (dispatch) => {
  const { startLoading, getEstadoGlobalSalud, setErrorGlobalSalud } = slice.actions;
  dispatch(startLoading());

  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/estadoGlobalSalud/${userId}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
    //console.log({ data });

    if (data.ok) {
      dispatch(getEstadoGlobalSalud(data));
      dispatch(getCategoriasBeneficios());
      return;
    }

    dispatch(setErrorGlobalSalud(data.message))
  } catch (error) {
    console.log(error);
    dispatch(setErrorGlobalSalud('Error al obtener el estado de salud, consulte con el administrador'))
  }
}

export const getCategoriasBeneficios = () => async (dispatch) => {
  const { startLoading, getBeneficios, setErrorGlobalSalud } = slice.actions;
  dispatch(startLoading());

  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/getCategoriasBeneficios`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
    //console.log({ data });

    if (data.ok) {
      dispatch(getBeneficios(data.categoria));
      dispatch(getParentescos());
      return;
    }

    dispatch(setErrorGlobalSalud(data.message));
  } catch (error) {
    console.log(error);
    dispatch(setErrorGlobalSalud('Error al obtener los beneficios, consulte con el administrador'))
  }
}

export const getParentescos = () => async (dispatch) => {
  const { setParentescos, startLoading } = slice.actions;

  dispatch(startLoading());
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get('/transactional/getParentescos', { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    if (data.ok) {
      return dispatch(setParentescos(data.listado));
    }

    dispatch(setErrorGlobalSalud(data.message));
  } catch (error) {
    console.log(error);
    dispatch(setErrorGlobalSalud('Error al obtener los parentescos, consulte con el administrador'));
  }
}

export const registrarBeneficiarios = ({ codigoCliente, codigoFondo, categoria, beneficiarios }) => async (dispatch) => {
  const { startLoading, setErrorGlobalSalud } = slice.actions;
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.post('/transactional/crearBeneficiarios', {
      codigoCliente,
      codigoFondo,
      categoria,
      beneficiarios,
    }, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    //console.log({ data });

    if (data.ok) {
      //console.log('ok');
      notification.success({
        message: 'Registro exitoso',
        description: 'La activación del plan Global Salud y el ingreso de tus beneficiarios ha sido exitoso.',
        duration: 8,
      });
      return;
    }

    message.error(data.message);
    // dispatch(setErrorGlobalSalud(data.message));
  } catch (error) {
    console.log(error);
  }
}

export const { setCurrentBeneficio, setNewBenficiarios } = slice.actions;

export default slice.reducer;
