"use client";

import { createSlice } from '@reduxjs/toolkit';
import { isValidToken, setSession, getSession } from '@layouts/CmsLayout/Auth/utils';
import cs_axs from '@axios/cs_axs';
import jwtDecode from 'jwt-decode';

const init = {
    usuario: {},
    token: null,
    roles: [],
    loading: false,
    error: false,

};


const slice = createSlice({
    name: "sesion",
    initialState: init,
    reducers: {
        loginFetch(state) {
            state.loading = true;
            state.error = false;
        },
        loginOk(state, action) {

            state.loading = false;
            state.error = false;
            state.usuario = action.payload.usuario;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            state.mechanism = action.payload.mechanism

        },
        loginError(state, action) {
            state.error = action.payload
            state.loading = false;
            state.usuario = {};
            state.token = null;
            state.roles = [];
            state.mechanism = null;

        },


    }
});


export default slice.reducer;





export const loginUsuario = (payload, callback) => async (dispatch) => {

    const { loginFetch, loginOk, loginError } = slice.actions;

    try {
        const token = getSession();

        ///LOGIN GUEST
        if (!token && !payload) {
            const response = await cs_axs.post(`/session/login`, {}, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'GUEST' } });

            if (response.data) {
                const token = response.data.token;

                if (isValidToken(token)) {
                    localStorage.setItem('token', token);
                    cs_axs.defaults.headers.common.Authorization = `Bearer ${token}`;
                    cs_axs.defaults.headers.common.guard = "GUEST"


                } else {
                    localStorage.removeItem('token', token);
                    delete cs_axs.defaults.headers.common.Authorization;
                    delete cs_axs.defaults.headers.common.guard;
                }

                dispatch(loginOk(response.data));
            }
        }

        ///LOGIN MANAGER
        if (payload) {
            dispatch(loginFetch());
            const response = await cs_axs.post(`/session/login`, payload, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'MANAGER' } });
            dispatch(loginOk(response.data));
            if (isValidToken(response.data.token)) setSession(response.data.token);
            return;
        }


        if (token) {
            const decoded = jwtDecode(token);
            const guard = decoded.id ? 'MANAGER' : 'GUEST';

            if (isValidToken(token)) {
                cs_axs.defaults.headers.common.Authorization = `Bearer ${token}`;
                cs_axs.defaults.headers.guard = guard;

            } else {
                localStorage.removeItem('token', token);
                delete cs_axs.defaults.headers.common.Authorization;
                delete cs_axs.defaults.headers.guard;
            }
            dispatch(loginFetch());

            const response = await cs_axs.get(`/session/me`);

            response.data && dispatch(loginOk(response.data));
        }

        return;
    } catch (error) {
        console.log(error)

    }
};
