"use client";

import { createSlice } from '@reduxjs/toolkit';


const init = {
    simulador: {
        aporteMensual: 50,
        incrementoAporteAnual: 5,
        plazoanios: 3,
        montoInicial: 0,
        daysInYear: 360,
        rendimiento: 1.10,
        proyeccion: [],
        proyeccionMicro: [],
        futureValue: {},
    },
    step: 0,
};

/*::: FÓRMULAS :::*/
const proyeccionYear = (initialValues, lap) => {
    const { montoInicial, aporteMensual, rendimiento, fondoAcumuladoCorte, rentabilidadMensualCorte, daysInYear } = initialValues;
    const aux = [];
    for (let index = 0; index < 12; index++) {
        const aporteAcumulado = aux[index - 1]
            ? aux[index - 1].aporteAcumulado + aporteMensual
            : montoInicial + aporteMensual;
        const fondoAcumulado = aux[index - 1]
            ? aporteMensual + aux[index - 1].rentabilidadMensual + aux[index - 1].fondoAcumulado
            : aporteMensual +
            (rentabilidadMensualCorte || 0) +
            (fondoAcumuladoCorte || montoInicial)
        const rentabilidadAcumulada = fondoAcumulado - aporteAcumulado;
        const rentabilidadMensual = (fondoAcumulado * rendimiento / 100 * 30) / daysInYear;
        aux.push({
            mes: `${lap}`,
            aporteMensual: aporteMensual,
            aporteAcumulado: aporteAcumulado,
            rentabilidadMensual: rentabilidadMensual,
            rentabilidadAcumulada: rentabilidadAcumulada,
            fondoAcumulado: fondoAcumulado
        })
    }
    return aux;
};

const proyeccionMacroFn = (data) => {
    const initialValues = {
        aporteMensual: parseFloat(data.aporteMensual),
        incrementoAporteAnual: parseFloat(data.incrementoAporteAnual),
        plazoanios: data.plazoanios,
        montoInicial: parseFloat(data.montoInicial),
        daysInYear: data.daysInYear,
        rendimiento: data.rendimiento
    };

    const aux = [];

    for (let index = 0; index < initialValues.plazoanios; index++) {

        const montoInicialCorte = (index > 0) ? aux[aux.length - 1].aporteAcumulado : 0;
        const aporteAcumuladoCorte = (index > 0) ? aux[aux.length - 1].fondoAcumulado : 0;
        const rentabilidadMensual = (index > 0) ? aux[aux.length - 1].rentabilidadMensual : 0;
        const aporteMensualCorte = (index > 0) ? aux[aux.length - 1].aporteMensual : 0;


        const iValues = index == 0
            ? initialValues
            : {
                montoInicial: montoInicialCorte,
                fondoAcumuladoCorte: aporteAcumuladoCorte,
                rentabilidadMensualCorte: rentabilidadMensual,
                aporteMensual: aporteMensualCorte + (aporteMensualCorte * initialValues.incrementoAporteAnual / 100),
                incrementoAnual: initialValues.incrementoAporteAnual,
                rendimiento: initialValues.rendimiento,
                daysInYear: initialValues.daysInYear
            }



        const proyeccion = proyeccionYear(iValues, index);


        aux.push(...proyeccion);
    }



    return aux.map(({ mes, aporteMensual, aporteAcumulado, rentabilidadMensual, rentabilidadAcumulada, fondoAcumulado }) => ({
        mes: mes,
        aporteMensual: aporteMensual.toFixed(2),
        aporteAcumulado: aporteAcumulado.toFixed(2),
        rentabilidadMensual: rentabilidadMensual.toFixed(2),
        rentabilidadAcumulada: rentabilidadAcumulada.toFixed(2),
        fondoAcumulado: fondoAcumulado.toFixed(2),
    }));


}

const proyeccionMicroFn = (proyeccion) => {
    const auxArr = []
    for (let i = 11; i < proyeccion.length; i += 12) {
        auxArr.push(proyeccion[i]);
    }

    // 
    return auxArr;
}

const proyeccionLastValueFn = (proyeccion) => {
    return proyeccion[proyeccion.length - 1];
}





const slice = createSlice({
    name: "simuladorHorizonte",
    initialState: init,
    reducers: {

        RESET(state, action) {
            state = { ...init };
        },


        CHANGE_STEP(state, action) {
            state.step = action.payload;
        },

        CHANGE_FIELD(state, action) {

            const payload = {
                ...state.simulador,//old
                ...action.payload,// new
            };

            const proyeccionMacro = proyeccionMacroFn(payload);
            const proyeccionMicro = proyeccionMicroFn(proyeccionMacro);
            const proyeccionLastValue = proyeccionLastValueFn(proyeccionMicro);

            state.simulador = {
                ...payload,
                proyeccionMacro,
                proyeccionMicro,
                proyeccionLastValue
            };
        },

        FILLFORM(state, action) {


            const payload = {
                ...state.simulador,//old
                ...action.payload,// new
            };

            const proyeccionMacro = proyeccionMacroFn(payload);
            const proyeccionMicro = proyeccionMicroFn(proyeccionMacro);
            const proyeccionLastValue = proyeccionLastValueFn(proyeccionMicro);

            state.simulador = {
                ...payload,
                proyeccionMacro,
                proyeccionMicro,
                proyeccionLastValue
            };
        },

    }
});

export default slice.reducer;




export const changeField = (payload, action, cb) => (dispatch) => {
    const actions = slice.actions;
    if (action === "CHANGE_FIELD") {
        dispatch(actions.CHANGE_FIELD(payload));
    }
};

export const changeStep = (step) => async (dispatch) => {
    dispatch(slice.actions.CHANGE_STEP(step))
}





