"use client";

import { createSlice } from '@reduxjs/toolkit';
import { axs } from '@axios/axsBaseUrl';

const initialState = {
    notificaciones: [],
    notificacion: {},
    loading: false,
    error: false,
};

const slice = createSlice({
    name: 'notificaciones',
    initialState,
    reducers: {
        findNotificacionesFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findNotificacionesSucess(state, action) {
            const { payload } = action
            state.notificaciones = payload;
            state.succes = true;
            state.error = false;
            state.loading = false;
        },
        findNotificacionesError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSucess(state, action) {
            const { payload } = action
            state.notificacion = payload.notificacion;
            state.loading = false;
        },
        createError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

    }
});

export const getNotificaciones = (fn) => async (dispatch) => {
    const { findNotificacionesFetch, findNotificacionesSucess, findNotificacionesError } = slice.actions;
    const token = localStorage.getItem('token') || '';
    // console.log({ token })
    try {
        dispatch(findNotificacionesFetch());
        const { data } = await axs.get(`${process.env.API_URL}/notificaciones/`,
            { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
        // console.log(data)
        const payload = data.notificaciones.map(notificacion => ({
            ...notificacion,
            imagen: `${process.env.API_URL}/notificaciones/image/${notificacion.imagen?.id}`
        }));

        dispatch(findNotificacionesSucess(payload))
    }
    catch (error) {
        console.log(error)
        dispatch(findNotificacionesError(error));
        fn('error', 'Error al consultar las notificaciones');
    }
}

export const createNotificacion = (data, form, fn) => async (dispatch) => {
    const { createFetch, createSucess, createError } = slice.actions;
    dispatch(createFetch());

    const token = localStorage.getItem('token') || '';

    try {
        const datos = await axs.post(`${process.env.API_URL}/notificaciones/`, data, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } })
        form.resetFields();
        dispatch(createSucess(datos.data));
        dispatch(getNotificaciones());
        fn('success', 'Notificación creada correctamente');
    } catch (error) {
        console.log(error)
        dispatch(createError(error));
        fn('error', 'Error al crear la notificación');
    }
};

export const actualizarNotificacion = (value, id, fn) => async (dispatch) => {
    const { createError } = slice.actions;
    try {
        await axs.put(`${process.env.API_URL}/notificaciones/${id}`, value)
        dispatch(getNotificaciones());
        fn('success', 'Notificación actualizada correctamente');
    } catch (error) {
        console.log(error)
        dispatch(createError(error));
        fn('error', 'Error al actualizar la notificación');
    }
}

export const eliminarNotificacion = (id, fn) => async (dispatch) => {
    // const { createFetch, createSucess, createError } = slice.actions;
    // dispatch(createFetch());
    try {
        await axs.delete(`${process.env.API_URL}/notificaciones/${id}`)
        dispatch(getNotificaciones());
        // dispatch(createSucess(datos.data));
        fn('success', 'Notificación eliminada correctamente');
    } catch (error) {
        console.log(error)
        // dispatch(createError(error));
        fn('error', 'Error al eliminar la notificación');
    }
}

export default slice.reducer;