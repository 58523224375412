"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { axs } from '@axios/axsBaseUrl';


const initialState = {
  estadosCuentas: [],
  dataPdf: {},
  loading: false,
  error: false,
};

const slice = createSlice({
  name: 'estaCuenta',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    getEstadoCuenta: (state, action) => {
      state.dataPdf = action.payload;
      state.loading = false;
    },
    getRetenciones: (state, action) => {
      state.dataPdf = action.payload;
      state.loading = false;
    },
    getError: (state) => {
      state.error = 'Error al obtener los datos';
      state.loading = false;
    },
    vaciarDataPdf: (state) => {
      state.dataPdf = {};
    },
    getEstadosCuentasDisponibles: (state, action) => {
      state.estadosCuentas = action.payload;
    }
  }
});


export const uiLoading = () => {
  return {
    type: slice.actions.startLoading.toString()
  }
}

export const startGetEstadosCuentasDisponibles = (uid, codigoFondo, setIsLoading) => async (dispatch) => {
  const { getEstadosCuentasDisponibles } = slice.actions;
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/EECCPeriodosDisponibles/${uid}/${codigoFondo}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    if (data.error) {
      message.error('Error al obtener los estados de cuenta disponibles');
      return;
    }

    const valor = data && data.length > 0 ? data : [];

    dispatch(getEstadosCuentasDisponibles(valor));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    message.error('Error en el servidor');
    // setIsLoading(false);
  }
};

export const startGetEstadoCuenta = ({ codigoFondo, idUsuario, fecha }, fn) => async (dispatch) => {
  const { getEstadoCuenta, getError } = slice.actions;

  try {
    const url = `/transactional/estado_cuenta/${codigoFondo}/${idUsuario}/${fecha.replace('-', '')}`;

    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(url, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    const { archivoBase64 } = data;
    const buffer = Buffer.from(archivoBase64, 'base64');
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const urlBlob = URL.createObjectURL(blob);

    // data.titulo = 'Estado de Cuenta';
    dispatch(getEstadoCuenta({
      url: urlBlob,
      titulo: 'Estado de Cuenta',
    }));

  } catch (error) {
    console.log(error);
    dispatch(getError());
  }
}

export const startGetRetencion = ({ codigoFondo, idUsuario, fecha }, fn) => async (dispatch) => {
  const { getRetenciones, getError } = slice.actions;

  try {
    const url = `/transactional/retencion/${codigoFondo}/${idUsuario}/${fecha.replace('-', '')}`;

    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(url, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    if (data.error) {
      fn('error', 'Error al obtener los datos');
      return;
    }

    data.titulo = 'Retención';

    const { archivoBase64 } = data;
    const buffer = Buffer.from(archivoBase64, 'base64');
    const blob = new Blob([buffer], { type: 'application/pdf' });
    const urlBlob = URL.createObjectURL(blob);

    dispatch(getRetenciones({
      url: urlBlob,
      titulo: 'Estado de Cuenta',
    }));

    // dispatch(getRetenciones(data));
  } catch (error) {
    console.log(error);
    dispatch(getError())
    fn('error', 'Error al obtener los datos');
  }
}

export const vaciarDataPdf = () => {
  return {
    type: slice.actions.vaciarDataPdf.toString(),
  }
}

export default slice.reducer;