"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { axs } from '@axios/axsBaseUrl';



const incrementoSuccessMsg = (fecha) => {
    const date = new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'numeric', day: '2-digit' }).format(new Date(fecha));
    notification.success({
        message: 'Incremento realizado con éxito.',
        description: `Tu incremento aplica a partir de la siguiente fecha ${date}.`,
    });
};


const errorMsg = () => {
    message.error('Error, intente de nuevo!');
};



const initialState = {
    aporteClienteWeb: {},
    aporteClienteWebSimulacion: null,
    novedad: false,
    loading: false,
    error: false,
};

const slice = createSlice({
    name: 'incremento',
    initialState,
    reducers: {

        findIncrementoConsultaFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findIncrementoConsultaSucess(state, action) {
            const { payload } = action
            state.aporteClienteWeb = payload.aporteClienteWeb;
            state.novedad = payload.novedad;
            state.error = payload.error;
            state.loading = false;
        },
        findIncrementoConsultaError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },


        findIncrementoSimulacionFetch(state) {
            state.loading = true;
            state.error = false;
        },

        findIncrementoSimulacionSucess(state, action) {
            const { payload } = action
            state.aporteClienteWebSimulacion = payload.aporteClienteWeb;
            state.novedad = payload.novedad;
            state.error = payload.error;
            state.loading = false;
        },
        findIncrementoSimulacionError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

        //APLICAR

        aplicarIncrementoFetch(state) {
            state.loading = true;
            state.error = false;
        },
        aplicarIncrementoSuccess(state, action) {
            const { payload } = action
            state.novedad = payload.novedad;
            state.error = payload.error;
            state.loading = false;
        },
        aplicarIncrementoError(state, action) {
            state.error = action.payload,
                state.loading = false;
        }

    }
});


export const findIncrementoConsulta = (consulta) => async (dispatch) => {
    const { findIncrementoConsultaFetch, findIncrementoConsultaSucess, findIncrementoConsultaError, findIncrementoSimulacionSucess } = slice.actions;

    try {
        dispatch(findIncrementoConsultaFetch());

        //console.log(consulta)

        if (consulta.montoAporte == 0 && consulta.tipoConsulta == 'S') {
            dispatch(findIncrementoSimulacionSucess({
                aporteClienteWeb: {
                    monto_aee: 0,
                    monto_aporte: 0,
                    monto_gs: 0,
                    monto_itp: 0,
                    monto_prima: 0,
                    monto_soluciona: 0,
                    observacion: "",
                    totalAporte: 0,
                },
                error: null,
                novedad: null,
            }));
            return;
        }

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.post(`/transactional/consultar_incremento`, consulta, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        // console.log(data)

        consulta.tipoConsulta == 'C'
            ? dispatch(findIncrementoConsultaSucess(data))
            : consulta.tipoConsulta == 'S'
            && dispatch(findIncrementoSimulacionSucess(data));

    }
    catch (e) {
        console.log(e)
        dispatch(findIncrementoConsultaError(e))
        message.success(e.message);
    }
}


export const aplicarIncremento = (obj) => async (dispatch) => {
    const { aplicarIncrementoFetch, aplicarIncrementoSuccess, aplicarIncrementoError } = slice.actions;
    //console.log(obj)
    try {
        dispatch(aplicarIncrementoFetch());

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.post(`/transactional/aplicar_incremento`, obj, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        // console.log(data)


        if (data.novedad) {
            notification.warning({
                message: 'Aviso!',
                description: data.novedad,
                duration: 8
            });
            return;
        }

        if (data.name === 'Error') {
            errorMsg()
        } else {
            incrementoSuccessMsg(data.fechaInicioAplicacion)
            dispatch(aplicarIncrementoSuccess(data))
        }

    }
    catch (e) {
        console.log(e)
        dispatch(aplicarIncrementoError(e))
    }
}

export default slice.reducer;