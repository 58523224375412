"use client";

import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';

const init = {
    entradaHasDocumento: {},
    entradaHasDocumentos: [],
    loading: false,
    error: false,
}

const slice = createSlice({
    name: 'entradaHasDocumento',
    initialState: init,
    reducers: {
        findFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findSucess(state, action) {
            state.entradaHasDocumentos = action.payload;
            state.loading = false;
        },
        findError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSucess(state, action) {
            state.entradaHasDocumentos = [...state.entradaHasDocumentos, action.payload];
            state.loading = false;
        },
        createError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

        deleteFetch(state) {
            state.loading = true;
            state.error = false;
        },
        deleteSucess(state, action) {

            const target = state.entradaHasDocumentos.findIndex(item => item.id == action.payload);
            const temp = [...state.entradaHasDocumentos];
            temp.splice(target, 1);

            state.entradaHasDocumentos = temp;
            state.loading = false;
        },
        deleteError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

    }
});

export default slice.reducer;


//RELACIONALES
export const findEntradaHasDocumento = () => async (dispatch) => {
    dispatch(slice.actions.findFetch())

    try {
        const { data } = await cs_axs.get(`/cms/entradaHasDocumentos`);

        dispatch(slice.actions.findSucess(data))
    } catch (error) {

        dispatch(slice.actions.findError(error))

    }
}


export const createEntradahasDocumento = ({ entradaId, documentoId }) => async (dispatch) => {

    const payload = {
        entrada: entradaId,
        documento: documentoId
    }
    dispatch(slice.actions.createFetch())

    try {
        const { data } = await cs_axs.post(`/cms/entradaHasDocumentos`, payload);
        dispatch(slice.actions.createSucess(data))
    } catch (error) {
        console.error(error)
        dispatch(slice.actions.createError(error))

    }
}



export const deleteEntradaHasDocumento = (edocId) => async (dispatch) => {

    dispatch(slice.actions.deleteFetch())

    try {
        const { data } = await cs_axs.delete(`/cms/entradaHasDocumentos/${edocId}`);

        dispatch(slice.actions.deleteSucess(edocId))
    } catch (error) {
        console.error(error)
        dispatch(slice.actions.deleteError(error))

    }
}