"use client";

import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';


const init = {
    simulacion: {
        hash: null,
        daysInYear: null,
        rendimiento: null,
        montoInversion: null,
        plazo: null,
        aporteMensual: null,
        incrementoAporteAnual: null,
        plazoanios: null,
        codigo_servicio: "000001"
    }
}

const slice = createSlice({
    name: 'simulador',
    initialState: init,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
            state.simulacion = init.clientePotencial
        },
        createOk(state, action) {
            const { payload } = action;
            state.loading = true;
            state.error = false;
            state.simulacion = action.payload;
        },
        createError(state, action) {
            const { payload } = action;
            state.error = payload
            state.loading = false;
            state.simulacion = init.clientePotencial
        },
    }
});
export default slice.reducer;



export const createSimulacion = (payload, callback) => async (dispatch) => {

    const simulacion = {
        titulo: payload.titulo,
        daysInYear: payload.daysInYear,
        rendimiento: payload.rendimiento,
        montoInversion: payload.montoInversion,
        plazo: payload.plazo,
        aporteMensual: payload.aporteMensual,
        incrementoAporteAnual: payload.incrementoAporteAnual,
        plazoanios: payload.plazoanios,
        codigo_servicio: payload.codigo_servicio
    }
    //console.log(simulacion);

    const { createFetch, createOk, createError } = slice.actions;
    try {
        dispatch(createFetch());
        const response = await cs_axs.post(`/cms/simulacion`, simulacion);
        //console.log(response.data);
        dispatch(createOk(response.data));
    } catch (error) {

        console.error(error)
        dispatch(createError(error));
    }
}