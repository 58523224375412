"use client";

import { createSlice } from '@reduxjs/toolkit';
import { jsFileToBlob } from 'utils/fileUtils';
import cs_axs from '@axios/cs_axs';

const init = {
    documentos: [],
    documento: null,
    entradasHasDocuments: [],
    loading: false,
    error: false,
    progress: 0,
};


const slice = createSlice({
    name: "documentos",
    initialState: init,
    reducers: {
        createDocumentoFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        createDocumentoSuccess(state, action) {
            state.loading = false;
            state.documentos.push(action.payload)
            state.documento = action.payload
        },
        createDocumentoProgress(state, action) {
            state.progress = action.payload;
        },
        createDocumentoError(state, action) {
            state.loading = false;
            state.error = action.payload
        },
        readDocumentosFetch(state, action) {
            state.loading = true;
            state.error = false;
        },
        readDocumentosSuccess(state, action) {
            state.loading = false;
            state.documentos = action.payload;
        },
        readDocumentosError(state, action) {
            state.loading = false;
            state.error = action.payload
        },

        //CUSTOM::
        setCurrent(state, action) {
            state.documento = action.payload;
        },

        setEntradasHasDocuments(state, action) {
            state.entradasHasDocuments = action.payload;
        },

        setNewEntradasHasDocuments(state, action) {
            state.entradasHasDocuments.push(action.payload);
        },

        deleteEntradaDoumento(state, action) {
            state.entradasHasDocuments = state.entradasHasDocuments.filter(({ id }) => id !== action.payload);
        }
    }
});

export const createDocumento = ({ titulo, file }) => async (dispatch) => {
    dispatch(slice.actions.createDocumentoFetch());
    try {
        const blobFile = await jsFileToBlob(file);

        const formData = new FormData()

        formData.append("File", blobFile);
        formData.append("name", titulo)

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                if (percent < 100) {
                    // console.log('triger percent');
                    dispatch(slice.actions.createDocumentoProgress(percent));
                }
            }
        };
        cs_axs.post(`/cms/documentos`, formData, options).then(res => {
            if (res.data && res.data.id) {
                const documento = {
                    ...res.data,
                    url: `/documentos/${res.data.id}`
                }
                dispatch(slice.actions.createDocumentoSuccess(documento))
            }
        });
    } catch (error) {
        dispatch(slice.actions.createDocumentoError(error));
    }
};

export const findDocumentos = () => async (dispatch) => {
    dispatch(slice.actions.readDocumentosFetch());
    try {
        const { data } = await cs_axs.get(`/cms/documentos`);
        dispatch(slice.actions.readDocumentosSuccess(data));
    } catch (error) {

        dispatch(slice.actions.readDocumentosError(error));
    }
}


export const setCurrentDocumento = (documento) => async (dispatch) => {
    dispatch(slice.actions.setCurrent(documento));
}
// 
export const startCreateEntradaDoumento = ({ entradaId, documentoId }) => async (dispatch) => {
    try {
        const { data } = await cs_axs.post(`/cms/crearEntradaDocumento`, {
            entradaId,
            documentoId
        });

        // console.log(data);
        dispatch(slice.actions.setNewEntradasHasDocuments(data));

    } catch (error) {
        debugger;
        console.log(error);
    }
}

export const startGetEntradasHasDocuments = () => async (dispatch) => {
    try {
        const { data } = await cs_axs.get(`/cms/getEntradasHasDocuments`);
        dispatch(slice.actions.setEntradasHasDocuments(data));
        // console.log(data)
    } catch (error) {
        console.log(error);
    }
}

export const startDeleteEntradaDoumento = ({ entradaId, documentoId }) => async (dispatch) => {
    try {
        const { data } = await cs_axs.delete(`/cms/eliminarEntradaDocumento/${entradaId}/${documentoId}`);

        if (data.error) {
            throw new Error(data.error);
        }

        dispatch(slice.actions.deleteEntradaDoumento(data.id));

    } catch (error) {
        console.log(error);

    }

}


export default slice.reducer;
