import jwtDecode from 'jwt-decode';
import axs from './axs';

export const isValidToken = (accessToken) => {
    if (!accessToken) return false
    const decoded = jwtDecode(accessToken);
    const valid = decoded.exp > (Date.now() / 1000);
    return valid;
};

export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('token', accessToken);
        axs.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('token');
        delete axs.defaults.headers.common.Authorization;
    }
};

export const getSession = () => {
    const token = localStorage.getItem('token');
    return token;
}
