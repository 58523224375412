"use client";

import { combineReducers } from '@reduxjs/toolkit';
import entradas from './slices/entradas';
import figuras from './slices/figuras';
import caracteristicas from './slices/caracteristicas';
import detalles from './slices/detalles';
import detalleItems from './slices/detalleItems';
import acciones from './slices/acciones';

import documentos from './slices/documentos';
import entradaHasDocumentos from './slices/entradaHasDocumentos';


import estadoCuentaReducer from './slices/estadoCuenta';
import movimientosReducer from './slices/movimientos';
import resumenPersonaReducer from './slices/resumenPersona';
import incrementoReducer from './slices/incremento';
import siniestrosReducer from './slices/siniestros';
import loginReducer from './slices/login';
import catalogosReducer from './slices/catalogos';
import notificacionesReducer from './slices/notificaciones';
import beneficiarios from './slices/beneficiarios';
import { retiroFCPReducer, retiroFLPReducer } from './slices/transaccional/retiros';
import solicitudesReducer from './slices/solicitudes';



import simulacion from './slices/simulacion';
import simuladorCortoPlazo from './slices/simuladorCortoPlazo';

import simuladorHorizonte from './slices/simuladorHorizonte';

import clientePotencial from './slices/clientePotencial'
import usuario from './slices/usuario';


import sesion from './slices/auth/sesion';
import ldap from './slices/auth/ldap'
import usuariosNotificacionesReducer from './slices/usuariosNotificaciones';
import otp from './slices/otp';
import counter from './slices/counter';


const rootReducer = combineReducers({
    entradas,
    figuras,
    documentos,
    caracteristicas,
    detalles,
    detalleItems,
    acciones,
    entradaHasDocumentos,


    movimientos: movimientosReducer,
    estadoCuenta: estadoCuentaReducer,
    resumenPersona: resumenPersonaReducer,
    incremento: incrementoReducer,
    siniestros: siniestrosReducer,
    login: loginReducer,
    catalogos: catalogosReducer,
    notificaciones: notificacionesReducer,
    beneficiarios: beneficiarios,
    retiroFCP: retiroFCPReducer,
    retiroFLP: retiroFLPReducer,
    usuarioNotificaciones: usuariosNotificacionesReducer,
    solicitudes: solicitudesReducer,


    simulacion,
    simuladorCortoPlazo,
    simuladorHorizonte,
    clientePotencial,
    usuario,
    sesion,
    ldap,
    otp,
    counter
});

export default rootReducer;