"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { axs } from '@axios/axsBaseUrl';


const initialState = {
    isRunning: false,
    showModal: false,
    loading: false,
    error: false,
    email: null,
    celular: null,
    validatedCode: false,
};

const slice = createSlice({
    name: 'otp',
    initialState,
    reducers: {

        getMediosOTPFetch(state) {
            state.isRunning = true;
            state.showModal = true;
            state.loading = true;
            state.error = false;
        },
        getMediosOTPSuccess(state, action) {
            const { payload } = action
            console.log({ payload })
            state.email = payload.email;
            state.celular = payload.celular;
            state.loading = false;
        },
        getMediosOTPError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        changeModalStatus(state, action) {
            state.showModal = action.payload;
        },

        changeLoadingStatus(state, action) {
            state.loading = action.payload;
        },

        changeValidateStatus(state, action) {
            state.validatedCode = action.payload;
        },

        resetState(state) {
            state.isRunning = false;
            state.showModal = false;
            state.loading = false;
            state.error = false;
            state.email = null;
            state.celular = null;
            state.validatedCode = false;
        }


    }
});

export const { getMediosOTPFetch, getMediosOTPSuccess, getMediosOTPError, changeModalStatus, resetState, changeLoadingStatus, changeValidateStatus } = slice.actions;



export const getOTPCode = (id) => async (dispatch) => {


    try {
        dispatch(getMediosOTPFetch());

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.get(`/user/getMediosOTP/${id}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
        console.log({ data })
        if (!data.ok) return message.error(data.message);
        dispatch(getMediosOTPSuccess(data));
    }
    catch (e) {
        console.log({ e })
        dispatch(getMediosOTPError(e.message));
        message.success(e.message);
    }
}


export const validarOTP = (identificacion, codigo, callback) => async (dispatch) => {

    try {

        dispatch(changeLoadingStatus(true));

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.post(`/user/validarOTP`, { identificacion, codigo },
            { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        console.log({ data })
        dispatch(changeLoadingStatus(false));

        if (!data.ok) {
            notification.error({
                message: '¡Error!',
                description: data.message
            });
        }

        if (data.ok) {
            notification.success({
                message: '¡Éxito!',
                description: data.message
            });
            callback();
            dispatch(changeValidateStatus(true));
        }

    } catch (error) {
        console.log(error)
    }

}



export const solicitarOTP = (identificacion, canal_envio, callback) => async (dispatch) => {

    dispatch(changeLoadingStatus(true));

    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.get(`/user/recibirOTPWeb/${identificacion}/${canal_envio}`, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        console.log({ data })
        dispatch(changeLoadingStatus(false));

        if (!data.ok) {
            notification.error({
                message: '¡Error!',
                description: data.message
            });
        }

        if (data.ok) {
            notification.success({
                message: '¡Éxito!',
                description: data.message
            });
            callback();
        }


    }
    catch (e) {
        console.log(e);
        notification.error({
            message: '¡Error!',
            description: data.message || 'Error interno del servidor!'
        });
    }
}




export const showOTPModal = () => async (dispatch) => {
    dispatch(changeModalStatus(false));
}


export default slice.reducer;