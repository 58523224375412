"use client";

import { createSlice } from "@reduxjs/toolkit";
import { Modal } from "antd";
import axios from "axios";
import { axs } from "@axios/axsBaseUrl";
import { fondos } from "@utils/catalogos_negocio";

const { success, error: errorDialog } = Modal;

const initialState = {
  codigoFondo: '000001',
  saldosXRubro: [],
  fondosDisponibles: [],
  bancos: [],
  tipoDeCuentasPorBanco: [],
  codigoMovimiento: '',
  codigoRubro: '',
  numeroDigitos: 0,
  formValues: {
    porcentajeRetiro: 0,
    montoRetiro: 0,
    comision: 0,
    porcComision: 0,
    motivoRetiro: 0,
    valorComision: 0,
  },
  lastFormValues: {
    porcentajeRetiro: 0,
    montoRetiro: 0,
    comision: 0,
    porcComision: 0,
    motivoRetiro: 0,
  },
  arrayRetiros: [],
  motivosRetiro: [],
  motivoRetiro: null,
  currentSaldo: null,
  error: null,
  novedad: '',
  isLoading: false,
}

const slice = createSlice({
  name: "RetiroFLP",
  initialState,
  reducers: {
    getSaldosXRubro(state, action) {
      state.saldosXRubro = action.payload;
    },
    getFondosDisponibles(state, action) {
      state.fondosDisponibles = action.payload;
    },
    setCurrentSaldo(state, action) {
      state.currentSaldo = {
        rubro: action.payload.rubro,
        saldo: action.payload.saldo,
      };
    },
    getBancoXFormaDePago(state, action) {
      state.bancos = action.payload;
    },
    getTipoDeCuentaPorBanco(state, action) {
      state.tipoDeCuentasPorBanco = action.payload.detalleBancos;
      state.numeroDigitos = action.payload.detalleBancos[0].numeroDigitos;
    },
    setFormValues(state, action) {
      state.formValues = action.payload;
    },
    setError(state, action) {
      state.error = true,
        state.novedad = action.payload;
    },
    resetFLP(state) {
      state.bancos = [];
      state.tipoDeCuentasPorBanco = [];
    },
    setArrayRetiros(state, action) {
      state.arrayRetiros = action.payload;
    },
    setNumeroDigitos(state, action) {
      state.numeroDigitos = action.payload;
    },
    setMotivosRetiro(state, action) {
      state.motivosRetiro = action.payload;
    },
    setCodigoMovimiento(state, action) {
      state.codigoRubro = action.payload.codigoRubro;
      state.codigoMovimiento = action.payload.codigoMovimiento;
    },
    setLastFormValues(state, action) {
      state.lastFormValues = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const startGetSaldosXRubro = (uid, codigoFondo) => async (dispatch) => {
  const { getSaldosXRubro, setError } = slice.actions;
  try {
    const { data } = await axs.get(`/transactional/getSaldosXRubro/${uid}/${codigoFondo}`, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    if (!data.error) {
      return dispatch(getSaldosXRubro(data.saldosPorRubro));
    }
    //console.log(data);
    // callback(data.novedad);
    dispatch(setError(data.novedad));
  } catch (error) {
    console.log(error);
    dispatch(setError('Error del Servidor'));
  }
}

export const startGetFondosDisponibles = (uid, codigoFondo) => async dispatch => {
  const { getFondosDisponibles, setError } = slice.actions;
  try {
    const { data } = await axs.get(`/transactional/getFondosDisponibles/${uid}/${codigoFondo}`, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    if (!data.error) {
      return dispatch(getFondosDisponibles(data.listado));
    }
    dispatch(setError(data.novedad));
  } catch (error) {
    console.log(error);
    dispatch(setError('Error del Servidor'));
  }
}

export const startGetBancoXFormaDePago = (formaPago, localidad) => async dispatch => {
  const { getBancoXFormaDePago, setError } = slice.actions;
  try {
    const { data } = await axs.get(`/transactional/getBancoXFormaDePago/${fondos[0].codigoFondo}/${formaPago}/${localidad}`, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    if (!data.error) {
      return dispatch(getBancoXFormaDePago(data.listado));
    }
    dispatch(setError(data.novedad || 'Error en la consulta'));
  } catch (error) {
    console.log(error);
    dispatch(setError('Error del Servidor'));
  }
}

export const startGetTipoDeCuentaPorBanco = (formaDePago, codigoBanco) => async dispatch => {
  const { getTipoDeCuentaPorBanco, setError } = slice.actions;
  try {
    const { data } = await axs.get(`${process.env.API_URL}/transactional/tipoCuentaPorBanco/${fondos[0].codigoFondo}/${formaDePago}/${codigoBanco}`, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });

    if (!data.error) {
      return dispatch(getTipoDeCuentaPorBanco(data));
    }

    dispatch(setError(data.novedad || 'Error en la consulta'));
  } catch (error) {
    console.log(error);
  }
}

export const startGetMotivosRetiro = () => async dispatch => {
  const { setMotivosRetiro } = slice.actions;

  try {
    const { data } = await axs.get(`${process.env.API_URL}/transactional/getMotivosRetiro`, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    dispatch(setMotivosRetiro(data));
  } catch (error) {
    console.log(error);
  }
}

export const startSendSolicitudRetiro = (values, callback) => async (dispatch, getState) => {
  const { retiroFLP, login } = getState(state => state);
  const { setIsLoading } = slice.actions;
  const identificacionCliente = login.user.numero_identificacion || login.user.ruc;

  dispatch(setIsLoading(true));
  try {
    const retiroObj = {
      identificacionCliente,
      codigoFondo: fondos[0].codigoFondo,
      montoTotalRetiro: retiroFLP.lastFormValues.montoRetiro,
      sinPenalidad: retiroFLP.lastFormValues.comision ? 0 : 1,
      motivoRetiro: retiroFLP.lastFormValues.motivoRetiro,
      codigoLocalidad: values.localidad,
      codigoFormaPago: values.formaDePago,
      codigoBanco: values.banco,
      tipoCuenta: values.tipoCuenta,
      numeroCuenta: values.numeroDeCuenta,
      detalleRetiro: [{
        codigoRubro: retiroFLP.codigoRubro,
        codigoMovimiento: retiroFLP.codigoMovimiento,
        montoRetiro: retiroFLP.lastFormValues.montoRetiro,
        porcentajeRetiro: retiroFLP.lastFormValues.porcentajeRetiro,
        montoComision: 0,
        porcentajeComision: 0,
        ultimoDetalle: 0
      }]
    }

    // console.log(JSON.stringify(retiroObj));
    await axs.post(`/transactional/solicitarRetiroFLP`, retiroObj, {
      headers: {
        'guard': 'TRANSAC',
        'x-access-token': localStorage.getItem('token') || '',
      }
    });
    dispatch(setIsLoading(false));

    success({
      title: 'Solicitud de Retiro Exitosa',
      content: 'Su retiro se encuentra en proceso de aprobación, este proceso puede demorar hasta 48 horas.',
      okButtonProps: {
        style: {
          marginLeft: 'auto'
        }
      },
      okText: 'Aceptar',
      onOk: () => callback(`/transacciones/${fondos[0].codigoFondo}`)
    });
  } catch (error) {
    console.log(error);
    dispatch(setIsLoading(false));
    const title = 'Ocurrió un error al realizar el retiro';
    let content = 'Error al realizar el retiro, por favor intente nuevamente.';
    if (axios.isAxiosError(error)) {
      content = error.response.data.message;
    }
    errorDialog({
      title,
      content,
      okButtonProps: {
        style: {
          marginLeft: 'auto'
        }
      },
      okText: 'Aceptar',
    });
  }
}

export const { setCurrentSaldo, setFormValues, resetFLP, setArrayRetiros, setNumeroDigitos, setCodigoMovimiento, setLastFormValues } = slice.actions;

export default slice.reducer;
