"use client";

import { createSlice } from '@reduxjs/toolkit';
import { axs } from '@axios/axsBaseUrl';


const initialState = {
    notificaciones: [],
    totalNotificaciones: 0,
    // notificacion: {},
    error: false,
};

const slice = createSlice({
    name: 'usuariosNotificaciones',
    initialState,
    reducers: {
        findNotificacionesFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findNotificacionesSucess(state, action) {
            const { listadoNotificaciones, cantidadNotificaciones } = action.payload
            state.notificaciones = listadoNotificaciones;
            state.totalNotificaciones = cantidadNotificaciones;
            state.error = false;
            state.loading = false;
        },
        findNotificacionesError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        marcarNotificacionLeida: (state, action) => {
            return {
                ...state,
                notificaciones: state.notificaciones.map(notificacion => {
                    if (notificacion.id === action.payload.id) {
                        return {
                            ...notificacion,
                            leida: true
                        }
                    }
                    return notificacion
                })
            }
        }
    }
});


export const getNotificaciones = ({ uid, fechaNacimiento, cantidad, lastDateUpdated }) => async (dispatch) => {
    const { findNotificacionesSucess, findNotificacionesError } = slice.actions;
    const token = localStorage.getItem('token') || '';


    try {
        const fecha = fechaNacimiento.split(' ')[0].split('/').reverse().join('-');
        const { data } = await axs.get(`/usuarioNotificaciones/${uid}/${lastDateUpdated}/${fecha}?cantidad=${cantidad}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
        const payload = data.notificacionesUsuarios.map(({ notificacion, leida, id }) => ({
            ...notificacion,
            id,
            leida,
            imagen: `${process.env.API_URL}/notificaciones/image/${notificacion.imagenId}`
        }));

        //sort by id desc
        payload.sort((a, b) => b.id - a.id);


        dispatch(findNotificacionesSucess({ listadoNotificaciones: payload, cantidadNotificaciones: data.cantidadNotificaciones }));
    }
    catch (error) {
        console.log(error)
        dispatch(findNotificacionesError(error));
    }
}

export const startLeerNotificacion = (id) => async dispatch => {
    const { marcarNotificacionLeida } = slice.actions;
    const token = localStorage.getItem('token') || '';

    try {
        await axs.post(`/usuarioNotificaciones/marcarLeida`, { id }, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        dispatch(marcarNotificacionLeida({ id }));
    } catch (error) {
        console.log(error);
    }
}

export default slice.reducer;