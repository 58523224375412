"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { axs } from '@axios/axsBaseUrl';


const initialState = {
    movimiento: [],
    novedad: false,
    loading: false,
    error: false,
};

const slice = createSlice({
    name: 'movimientos',
    initialState,
    reducers: {

        findMovimientosFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findMovimientosSucess(state, action) {
            const { payload } = action
            state.movimiento = payload.movimiento;
            state.novedad = payload.novedad;
            state.error = false;
            state.loading = false;
        },
        findMovimientosError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

    }
});


export const findMovimientos = (consulta) => async (dispatch) => {
    const { findMovimientosFetch, findMovimientosSucess, findMovimientosError } = slice.actions;

    //console.log({consulta});

    try {
        dispatch(findMovimientosFetch());
        // const { data } = await axios.post(`${process.env.API_URL}/transactional/movimientos`, consulta)

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.post(`/transactional/movimientos`, consulta, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        if (data.ok && data.movimientos) {
            dispatch(findMovimientosSucess(data.movimientos));
        }

        if (!data.ok) {
            dispatch(findMovimientosError(data.message))
            message.error(data.message);
        }
    }
    catch (e) {
        message.error('Error interno del servidor!');
        console.log(e)
        dispatch(findMovimientosError(e))
    }
}


export default slice.reducer;