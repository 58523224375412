"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message, notification } from 'antd';
import { axs } from '@axios/axsBaseUrl';
import { fondoHorizonte } from '@utils/catalogos_negocio';



const initialState = {
    tipoCoberturas: [],
    coberturaSelected: null,
    loading: false,
    next: true,
    novedad: '',
    error: '',
    enfermedadesPorCobertura: [],
    errorEnfermedad: '',
    novedadEnfermedad: '',
    errorLogSiniestro: '',
    novedadLogSiniestro: '',
    errorCreateSiniestro: '',
    novedadCreateSiniestro: '',
    loadingCreateSiniestro: false,
    createSiniestroSuccess: null,
    solicitudGraveActiva: null,
    solicitudGraveEnfermedad: '',
    diagnosticoSelected: null,
    coberturaIsValid: null,
    clickMainOnClose: false
};

const slice = createSlice({
    name: 'siniestros',
    initialState,
    reducers: {

        resetState: (state) => {
            state.coberturaSelected = null,
                state.loading = false,
                state.next = false,
                state.novedad = '',
                state.error = '',
                state.errorEnfermedad = '',
                state.novedadEnfermedad = '',
                state.errorLogSiniestro = '',
                state.novedadLogSiniestro = '',
                state.errorCreateSiniestro = '',
                state.novedadCreateSiniestro = '',
                state.loadingCreateSiniestro = false,
                state.createSiniestroSuccess = null,
                state.solicitudGraveActiva = null,
                state.solicitudGraveEnfermedad = '',
                state.diagnosticoSelected = null,
                state.coberturaIsValid = null,
                state.clickMainOnClose = false
        },
        selectCoberturaAction: (state, action) => {
            state.coberturaSelected = action.payload;
        },
        findCoberturasFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findCoberturasSucess(state, action) {
            const { payload } = action
            state.tipoCoberturas = payload.listado;
            state.novedad = payload.novedad;
            state.error = payload.error;
            state.loading = false;
        },
        findCoberturasError(state, action) {
            state.error = action.payload;
            state.novedad = action.payload.novedad;
            state.loading = false;
        },
        enfermedadCoberturaSuccess(state, action) {
            state.enfermedadesPorCobertura = action.payload.listado;
            state.errorEnfermedad = action.payload.error;
            state.novedadEnfermedad = action.payload.novedad;
        },
        enfermedadCoberturaError(state, action) {
            state.enfermedadesPorCobertura = [];
            state.errorEnfermedad = action.payload.error;
            state.novedadEnfermedad = action.payload.novedad;
        },
        logSiniestroSuccess(state, action) {
            state.errorLogSiniestro = action.payload.error;
            state.novedadLogSiniestro = action.payload.novedad;
        },
        createSiniestroFetch(state) {
            state.loadingCreateSiniestro = true;
        },
        createSiniestro(state, action) {
            state.errorCreateSiniestro = action.payload.error;
            state.novedadCreateSiniestro = action.payload.novedad;
            state.loadingCreateSiniestro = false;
        },
        createSiniestroResponse(state, action) {
            state.createSiniestroSuccess = action.payload;
        },
        solicitudActivaState(state, action) {
            state.solicitudGraveActiva = action.payload.ok;
            state.solicitudGraveEnfermedad = action.payload.codigoEnfermedad;
        },
        diagnosticoSelect(state, action) {
            state.diagnosticoSelected = action.payload;
        },
        changeNextStatus(state, action) {
            state.next = action.payload
        },
        changeEnfermedadActiva(state, action) {
            state.solicitudGraveActiva = action.payload
        },
        changeLoadingSiniestro(state, action) {
            state.loading = action.payload
        },
        changeValidateCobertura(state, action) {
            state.coberturaIsValid = action.payload
        },
        changeClickMainOnClose(state, action) {
            state.clickMainOnClose = action.payload
        }

    }
});


export const {
    resetState,
    selectCoberturaAction,
    findCoberturasFetch,
    findCoberturasSucess,
    findCoberturasError,
    enfermedadCoberturaSuccess,
    enfermedadCoberturaError,
    logSiniestroSuccess,
    createSiniestroFetch,
    createSiniestro,
    createSiniestroResponse,
    solicitudActivaState,
    changeEnfermedadActiva,
    changeLoadingSiniestro,
    diagnosticoSelect,
    changeNextStatus,
    changeValidateCobertura,
    changeClickMainOnClose
} = slice.actions;


export const resetSiniestroState = () => (dispatch) => {
    dispatch(resetState());
};


export const findTipoCoberturas = (consulta) => async (dispatch) => {


    try {
        dispatch(findCoberturasFetch());
        const token = localStorage.getItem('token') || '';
        const { data } = await axs.get(`/transactional/siniestros/coberturas`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        //console.log(data);

        data.ok
            ? dispatch(findCoberturasSucess(data))
            : dispatch(findCoberturasError(data));

    }
    catch (e) {
        console.log(e)
        //dispatch(findCoberturasError(data));
        //message.success(e.message);
    }
}


export const notificaLogSiniestro = (consulta, modal, loading) => async (dispatch) => {

    // console.log(consulta)
    try {
        const token = localStorage.getItem('token') || '';

        const { data } = await axs.post(`/transactional/siniestros/notificaLogSiniestro`, consulta, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        dispatch(logSiniestroSuccess(data));
        loading(false);
        modal(true);
    }
    catch (e) {
        console.log(e)
        loading(false);
        dispatch(logSiniestroSuccess({ error: e.message }));
    }
}


export const enfermedadPorCobertura = (consulta) => async (dispatch) => {

    try {
        const token = localStorage.getItem('token') || '';
        const { data } = await axs.get(`/transactional/siniestros/coberturas/${consulta}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });



        //console.log(data)

        data.ok
            ? dispatch(enfermedadCoberturaSuccess(data))
            : dispatch(enfermedadCoberturaError(data));

    }
    catch (e) {
        console.log(e)
        //dispatch(findCoberturasError(data));
        //message.success(e.message);
    }
}


export const insertarSiniestro = (consulta, router, loading, showToolBar) => async (dispatch) => {

    let siniestros = localStorage.getItem('siniestros') || '[]';
    siniestros = JSON.parse(siniestros);

    let datos = consulta == undefined ? siniestros : consulta;

    try {

        dispatch(createSiniestroFetch());

        const token = localStorage.getItem('token') || '';
        const { data } = await axs.post(`/transactional/siniestros/create`, datos, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        //console.log(data);

        loading(false);
        if (data.ok) {
            notification.success({
                message: 'Solicitud Creada',
                description: 'La notificación del siniestro fue ingresada con éxito. Te llegará un mail y sms de confirmación a la dirección de correo y/o celular registrado en nuestro sistema.',
                duration: 7,
            });
            dispatch(createSiniestroResponse(data.ok))
            dispatch(createSiniestro({ error: null, novedad: null }));
            localStorage.removeItem('siniestros');
            dispatch(resetSiniestroState());
            showToolBar(true);
        } else {
            dispatch(createSiniestro({ error: 'error', novedad: 'error' }));
            message.error('Error al registrar Solicitud de Siniestro.');
        }


    }
    catch (e) {
        console.log('Error Create Siniestro :', e.message)
        message.warning('Error al crear siniestro.')
        dispatch(createSiniestro({ error: e.message }));

    }

}


export const resetValidaAnticipo = () => (dispatch) => {
    dispatch(solicitudActivaState({ ok: null, codigoEnfermedad: '' }));
}


export const setDiagnostico = (consulta) => (dispatch) => {
    dispatch(diagnosticoSelect(consulta));
}



export const createSiniestroResult = (consulta) => (dispatch) => {
    dispatch(createSiniestroResponse(consulta));
}


export const addSiniestrosLocalStorage = (consulta, loading, router) => (dispatch) => {
    //console.log(consulta);
    consulta.created_at = (new Date()).toLocaleDateString();
    let siniestros = localStorage.getItem('siniestros') || '[]';
    siniestros = JSON.parse(siniestros);


    //validar si existen siniestros previos relacionados
    const siniestroExists = siniestros.filter(item => item.tipoCobertura == consulta.tipoCobertura);
    if (siniestroExists.length > 0) {
        router.push('/transacciones/siniestros/documentViewer');
        loading(false);
        return;
    }


    siniestros.push(consulta);
    localStorage.setItem('siniestros', JSON.stringify(siniestros));
    router.push('/transacciones/siniestros/documentViewer');
    loading(false);
}



export const selectCoberturaFinal = (cobertura, codigo_cliente, loading, setEnfermedad) => async (dispatch) => {
    dispatch(changeNextStatus(false));
    dispatch(selectCoberturaAction(cobertura));
    dispatch(changeLoadingSiniestro(true));

    try {

        let data;
        let response;
        let dataAnticipo;
        const token = localStorage.getItem('token') || '';

        let datos = {
            codigoCliente: codigo_cliente,
            codigoFondo: fondoHorizonte.codigoFondo,
            tipoCobertura: cobertura
        }


        if (!datos.codigoEnfermedad) {
            response = await axs.post(`/transactional/siniestros/validaCobertura`, datos,
                {
                    headers: {
                        'guard': 'TRANSAC',
                        Authorization: `Bearer ${token}`,
                        'x-access-token': token
                    }
                });
            data = response.data;
            //console.log(data);
        }


        loading(false);
        dispatch(changeLoadingSiniestro(false));

        if (data.ok == true) {

            if (data.loadMessage && data.loadMessage !== 'null') {
                notification.warning({
                    message: '¡ Aviso !',
                    description: cobertura == '001'
                        ? data.loadMessage
                        : `Ya existe una notificación y/o caso ${data.loadMessage || data.codigoEnfermedad} por lo cual es necesario gestionar el requerimiento acudiendo personalmente a las oficinas de GENESIS hasta en 48 horas. Cualquier duda puedes comunicarte a nuestra línea de atención 7200200`,
                    duration: 8
                })
                return;
            }

            if (cobertura == '002' || cobertura == '003') {
                response = await axs.post(`/transactional/siniestros/validaAnticipoEnfermedad`, datos,
                    {
                        headers: {
                            'guard': 'TRANSAC',
                            Authorization: `Bearer ${token}`,
                            'x-access-token': token
                        }
                    });

                dataAnticipo = response.data;
                //console.log(dataAnticipo);


                if (dataAnticipo.ok == false) {
                    notification.error({
                        message: '¡ Aviso !',
                        description: dataAnticipo.novedad,
                        duration: 8
                    })
                    return;
                }


                if (dataAnticipo.codigoEnfermedad && dataAnticipo.codigoEnfermedad !== 'null') {
                    setEnfermedad(dataAnticipo.codigoEnfermedad);
                }
            }

            dispatch(changeNextStatus(true));
        } else {
            notification.error({
                message: '¡ Aviso !',
                description: data.novedad,
                duration: 8
            })
        }


    } catch (error) {
        console.log(error);
        loading(false);
        dispatch(changeLoadingSiniestro(false));
    }

}



export const validarCobertura = (consulta, disabledBtn, loadingBtn) => async (dispatch) => {
    //dispatch(changeValidateCobertura(null));

    const token = localStorage.getItem('token') || '';
    const { data } = await axs.post(`/transactional/siniestros/validaCobertura`, consulta,
        {
            headers: {
                'guard': 'TRANSAC',
                Authorization: `Bearer ${token}`,
                'x-access-token': token
            }
        });

    // console.log(data);
    if (data.ok) {
        if (data.loadMessage !== 'null') {
            if (data.loadMessage == 'EN TRÁMITE') {
                //dispatch(changeValidateCobertura(false));
                notification.info({
                    message: '¡ Aviso !',
                    description: `Ya existe una notificación y/o caso ${data.loadMessage} por lo cual es necesario gestionar el requerimiento acudiendo personalmente a las oficinas de GENESIS hasta en 48 horas. Cualquier duda comunícate a nuestra línea de atención 7200200`,
                    duration: 8
                })
                loadingBtn(false);
                return;
            }
            notification.info({
                message: '¡ Aviso !',
                description: data.loadMessage,
                duration: 8
            })
        }
        //dispatch(changeValidateCobertura(true));
        disabledBtn(false);

    } else {
        //dispatch(changeValidateCobertura(false));
        notification.warning({
            message: '¡ Aviso !',
            description: data.novedad,
            duration: 8
        })
    }
    loadingBtn(false);
}


export const { createSiniestroSuccess } = slice.actions;
export default slice.reducer;