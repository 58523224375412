"use client";

import { createSlice } from '@reduxjs/toolkit';
import { fondosObj } from '@utils/catalogos_negocio';
import { notification } from 'antd';
import cs_axs from '@axios/cs_axs';

const init = {
    clientePotencial: {
        nombres: "",
        telefono: "",
        correo: "",
        mensaje: "",
        entradaId: "",
        simulacionId: ""
    },
    loading: false,
    error: false,

}



const slice = createSlice({
    name: 'clientePotencial',
    initialState: init,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
            state.clientePotencial = init.clientePotencial
        },
        createOk(state, action) {
            const { payload } = action;
            state.loading = false;
            state.error = false;
            state.clientePotencial = action.payload;
        },
        createError(state, action) {
            //console.log(action.payload);
            const { payload } = action;
            state.error = payload
            state.loading = false;
            state.clientePotencial = init.clientePotencial
        },

        findFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findOk(state, action) {
            const { payload } = action
            state.loading = false;
            state.error = false;
            state.clienteHasSimulaciones = payload.clienteHasSimulaciones;
            state.clientePotenciales = payload.clientePotenciales;
            state.simulaciones = payload.simulaciones.map(item => ({
                ...item,
                descripcion_servicio: fondosObj[item.codigo_servicio]
            }));
        },
        findError(state, action) {
            const { payload } = action;
            state.error = payload
            state.loading = false;
        },

    }
});

export default slice.reducer;



export const createClientePotencial = (payload, callback) => async (dispatch) => {
    const { createFetch, createOk, createError } = slice.actions;
    try {
        dispatch(createFetch());
        const response = await cs_axs.post(`/cms/clientePotencial`, payload);
        callback()
        dispatch(createOk(response.data))
    } catch (error) {
        dispatch(createError(error));
        notification.error({
            message: 'Lo sentimos hemos tenido un problema al registrar su solicitud',
            placement: 'top'
        })
    }
}
export const findClientePotencial = (payload) => async (dispatch) => {
    const { findFetch, findError, findOk } = slice.actions;
    try {
        dispatch(findFetch());
        const { data } = await cs_axs.get(`/cms/clientePotencial`, {
            params: {
                dateIni: payload[0],
                dateEnd: payload[1]
            }
        });
        data && dispatch(findOk(data))
    } catch (error) {
        dispatch(findError(error));
    }
}