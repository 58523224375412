import axios from 'axios';

const cs_axs = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'guard': 'GUEST'
    },
});




export default cs_axs