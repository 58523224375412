"use client";

import { createSlice } from '@reduxjs/toolkit';
import { axs } from '@axios/axsBaseUrl';


const indexCatalogos = ['PR', 'CA', 'PA', 'NA', 'CI'];


const initialState = {
    provincias: [],
    cantones: [],
    parroquias: [],
    nacionalidades: [],
    estados_civiles: [],

    error: false,
    loading: false
};


const slice = createSlice({
    name: 'catalogos',
    initialState,
    reducers: {
        getCatalogosFetch(state) {
            state.loading = true;
            state.error = false;
        },
        getCatalogosSuccess(state, action) {
            const { payload } = action;
            state.provincias = payload.PR || [];
            state.cantones = payload.CA || [];
            state.parroquias = payload.PA || [];
            state.nacionalidades = payload.NA || [];
            state.estados_civiles = payload.CI || [];
            state.error = false;
            state.loading = false;
        },
        getCatalogosError(state, action) {
            state.error = action.payload;
            state.loading = false;
        }
    }
});


export const getCatalogos = () => async (dispatch) => {
    const { getCatalogosFetch, getCatalogosSuccess, getCatalogosError } = slice.actions;
    try {
        dispatch(getCatalogosFetch());
        const dataCatalogos = {};
        const token = localStorage.getItem('token');
        const responses = await Promise.allSettled(indexCatalogos.map((value) => {
            return axs.get(`/transactional/catalogos/${value}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } })
        }));
        // console.log({responses});

        indexCatalogos.forEach((value, i) => {
            dataCatalogos[value] = responses[i].value.data;
        });

        dispatch(getCatalogosSuccess(dataCatalogos));
    } catch (e) {
        console.log(e)
        console.log('Error al obtener los catalogos');
        dispatch(getCatalogosError(e));
    }
};

export default slice.reducer;