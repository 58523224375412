"use client";

import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import cs_axs from '@axios/cs_axs';


const initialState = {
    detalles: [],
    detalle: {
        id: null,
        titulo: null,
        descripcion: null,
        url: null,
        orden: null,
    },
    loading: false,
    error: false,
    loaded: false
};

// find, findOne, create, update, remove

const slice = createSlice({
    name: 'detalles',
    initialState,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSuccess(state, action) {
            state.loading = false;
            state.detalles = [...state.detalles, action.payload];
        },
        createError(state, action) {
            state.loading = false;
            state.error = true;
        },

        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateSuccess(state, action) {
            const index = state.detalles.findIndex(item => item.id == action.payload.id);
            const aux = [...initialState.detalles];
            aux[index] = action.payload;
            //modifico store
            state.loading = false;
            state.detalles = aux;
        },
        updateError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },



        toggleLoading(state, action) {
            state.loading = action.payload;
        },


        fillForm(state, action) {
            state.detalle = action.payload;
        },
        cleanForm(state, action) {
            state.detalle = initialState.detalle;
        },

    }
});


export const createDetalle = (payload) => async (dispatch, callback) => {
    const { createSuccess, createError, createFetch } = slice.actions;


    try {
        dispatch(createFetch());
        const { data } = await cs_axs.post(`/cms/detalles`, payload);
        callback();
        dispatch(createSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(createError(e));
    }
}

export const updateDetalle = (payload) => async (dispatch, callback) => {
    const { updateFetch, updateSuccess, updateError } = slice.actions;
    try {
        dispatch(updateFetch());
        const { data } = await cs_axs.put(`/cms/detalles/${payload.id}`, payload);
        callback();
        dispatch(updateSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(updateError(e));
    }
}

export const deleteDetalle = (id) => async (dispatch, callback) => {
    try {
        dispatch(slice.actions.toggleLoading(true));
        const response = await cs_axs.delete(`/cms/detalles/${id}`);
        if (response && response.data && response.data.id) {

            callback();
            dispatch(slice.actions.toggleLoading(false));
        }

    } catch (e) {
        message.error('Lo sentimos intente nuevamente');
        dispatch(slice.actions.toggleLoading(false));

    }
}

export const fillFormDetalle = (payload) => async (dispatch, cb) => {

    dispatch(slice.actions.fillForm(payload))
    // : dispatch(slice.actions.cleanForm());
}




export default slice.reducer;




