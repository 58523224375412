"use client";

import { createSlice } from '@reduxjs/toolkit';
import { axs } from '@axios/axsBaseUrl';

const initialState = {
  solicitudesUsuario: [],
  listado: [],
  fondos: [
    {
      codigo: '000001',
      nombre: 'Fondo Horizonte'
    },
    {
      codigo: '000029',
      nombre: 'Fondo Master'
    },
    {
      codigo: '000033',
      nombre: 'Fondo Estratégico'
    },
    {
      codigo: '000038',
      nombre: 'Fondo Renta Plus'
    },
    {
      codigo: '000040',
      nombre: 'Fondo Superior'
    }
  ],
  objReclamo: {
    nivel1: '',
    nivel2: '',
    descripcion: '',
    codigoFondo: '',
  },
  isLoading: true,
  solicitudUsuario: {},
  isError: false,
}

const slice = createSlice({
  name: 'solicitud',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getListadoSolicitudesUsuarios: (state, action) => {
      return {
        ...state,
        solicitudesUsuario: action.payload || [],
        isLoading: false,
        isError: false
      }
    },
    getListadoSolicitudes: (state, action) => {
      state.listado = action.payload;
      state.isError = false;
    },
    setObjReclamo: (state, action) => {
      return {
        ...state,
        objReclamo: {
          ...state.objReclamo,
          ...action.payload
        },
        isError: false,
        isLoading: false,
      }
    },
    setSolicitudUsuario: (state, action) => {
      state.solicitudUsuario = action.payload;
      state.isLoading = false;
      state.isError = false;
    },
    setError(state) {
      state.isLoading = false;
      state.isError = true;
    }
  }
});

export const startGetListadoSolicitudesUsuario = (consulta, callback) => async (dispatch) => {
  // console.log({consulta})
  try {
    const token = localStorage.getItem('token') || '';
    // console.log(token);
    const { data } = await axs.post(`/transactional/solicitudesUsuario`, consulta, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    callback(false);

    dispatch(slice.actions.getListadoSolicitudesUsuarios(data));
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.setError());
    callback(false);
  }
}

export const startGetSolicitudUsuario = id => async dispatch => {
  dispatch(slice.actions.startLoading());
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/solicitudUsuario/${id}`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
    dispatch(slice.actions.setSolicitudUsuario(data));
  } catch (error) {
    console.log(error);
  }
}

export const startGetListadoSolicitudes = () => async dispatch => {
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.get(`/transactional/listado-solicitudes`, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

    dispatch(slice.actions.getListadoSolicitudes(data.listado));
  } catch (error) {
    console.log(error);
  }
}

export const sendSolicitud = (solicitud, fn, router, loading) => async (dispatch) => {
  if (!solicitud.codigoCliente) return;
  try {
    const token = localStorage.getItem('token') || '';
    const { data } = await axs.post(`/transactional/sendSolicitud`, solicitud, { headers: { 'guard': 'TRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });
    fn('success', data.msg);

    console.log({ data })
    loading(false);
    router.push('/solicitudes');

  } catch (error) {
    console.log(error);
    fn('error', error.response?.data?.message);
    // fn('error', 'Error al enviar la solicitud, es probable que ya se haya enviado una queja para este Fondo');
  }
}

export const { setObjReclamo, setError } = slice.actions;

export default slice.reducer;
